import { Avatar, Button, Card, CircularProgress, Typography, Tabs, Tab } from '@mui/material';
import React, { useState, useEffect } from 'react';
import verboace from './../images/verboace.png';
import { useQuizContext } from '../quizContext';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { connect, useDispatch } from 'react-redux';
import '../App.css';
import { apiendpoint } from '../endpoint';
import axios from 'axios';
import MyCard from './myCard';
import ProfileCard from './profileCard';
import ContentLoader from 'react-content-loader';
import { logout } from '../authActions';
import ConversationModal from './ConversationModal';
import ContentCard from './contentCard';
import CustomModal from './customModal';
import Countdown from 'react-countdown';
import rewardAnimation from '../images/timer.json';
import Lottie from 'lottie-react';
import TestResults from './testResults';
import ReadingGame from './readingGame';
import QuoraActivity from './../quora'
import Buddy from './buddy';
import MentorBooking from './mentorBooking';
import TopperCarousel from './topperCarousel';
import MentorCard from './topping';


function NotificationsBox({ token }) {
    const [notifications, setNotifications] = useState([]);
    const [loading, setLoading] = useState(true);
    
   
  
    useEffect(() => {
      // Fetch the notifications from the API
      const fetchNotifications = async () => {
        try {
            const headers = {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            };
          const response = await axios.post(`${apiendpoint.path}/v1/users/comments`,{}, {
            headers,
            
          });
          console.log(response.data)
          setNotifications(response.data.comments);
        } catch (error) {
          console.error("Error fetching notifications", error);
        } finally {
          setLoading(false);
        }
      };
  
      fetchNotifications();

    }, [token]);
  
    if (loading) {
      return <CircularProgress />;
    }
  
    return (
      <Card style={{ padding: '20px', marginBottom: '20px' }}>
        <Typography variant="h6" gutterBottom>
         Last 10 Notifications
        </Typography>
        {notifications.map((notification) => (
          
          <Card key={notification.id} style={{ marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
            <Avatar src={notification.display_pic} alt={notification.display_name} style={{ margin: '15px',height:32,width:32 }} />
            <div>
              <Typography variant="body1" style={{textAlign:"left",padding:8}}>
                {notification.comment=="clap"?notification.display_name+" Clapped for you":notification.comment}
              </Typography>
              <Typography variant="body2" color="textSecondary" style={{padding:4}}>
                {new Date(notification.created_at).toLocaleString()}
              </Typography>
            </div>
          </Card>
        ))}
      </Card>
    );
  }
  

function Dashboard({ isLoggedIn, user, token }) {
  
    const examDate = new Date('2024-11-24T00:00:00');
    const { duration, setDuration, pallette, setPallette, completed, setCompleted, score, setScore, login, setLogin } = useQuizContext();
    const navigation = useHistory();
    const [data, setData] = useState(null);
    const [dataTest, setDataTest] = useState(null);
    const [loading, setLoading] = useState(true);
    const [testloading, settestloading] = useState(false);
    const [modal, setModal] = useState(false);
    const [showInstallModal, setShowInstallModal] = useState(false);
    const [weakAreas, setWeakAreas] = useState([]);
    const [isRandomAreas, setIsRandomAreas] = useState(false);
    const [selectedRcId, setSelectedRcId] = useState(null);
    const [loadingWeakAreas, setLoadingWeakAreas] = useState(false);

    const [activeTab, setActiveTab] = useState(0);
      // State for installation modal
    
    const [weakArea, setWeakArea] = useState(null);
    const [weakCategory, setweakCategory] = useState(null);
    const dispatch = useDispatch()
    const [notifications, setNotifications] = useState([]);
    const [mentors,setMentors] = useState([])

    const fetchMentors = async () => {
      setLoading(true);
      try {
        const response = await fetch(`${apiendpoint.path}/v1/auth/toppers`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        const data = await response.json();
        console.log(data)
        setMentors(data.data);
      } catch (error) {
        // showSnackbar('Failed to load mentors', 'error');
      }
      setLoading(false);
    };
 

    useEffect(() => {
        if (!isLoggedIn) {
            navigation.replace("/");
        }
    }, [isLoggedIn]);


    useEffect(() => {
        const hasShownModal = localStorage.getItem('hasShownModal');
        if (!hasShownModal) {
            setShowInstallModal(true);
            localStorage.setItem('hasShownModal', 'true');
        }
    }, []);

    const fetchTest = async () => {
        try {
            settestloading(true)
            const headers = {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            };

            const postData = selectedRcId ? { rcId: selectedRcId } : {};

            const response = await axios.post(apiendpoint.path + "/v1/users/getfreshrcweb", postData, { headers });
            console.log(response);
            setDataTest(response.data.rcDetails)
            if(response.data.weakAreamain==null||response.data.weakCategorymain==null){
                navigation.push('/testing',{data:response.data.rcDetails})
            }
            else{
                setWeakArea(response.data.weakAreamain)
                setweakCategory(response.data.weakCategorymain)
                setModal(true)
            }
            // navigation.push('/testing',{data:response.data.rcDetails})
            settestloading(false);
        } catch (error) {
            dispatch(logout(
            )); 
            navigation.replace('/')
            console.error('Error fetching posts:', error);
            settestloading(false);
        }
    };


    const fetchWeakAreas = async () => {
        setLoadingWeakAreas(true);
        try {
          const headers = {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          };
          const response = await axios.get(`${apiendpoint.path}/v1/users/weakareas`, { headers });
          setWeakAreas(response.data.weakAreas);
          setIsRandomAreas(response.data.isRandom);
        } catch (error) {
          console.error('Error fetching weak areas:', error);
        } finally {
          setLoadingWeakAreas(false);
        }
      };
    


      const renderWeakAreasSection = () => {
        if (loadingWeakAreas) {
          return <CircularProgress color={"primary"} style={{margin:10,color:"#7f388b"}} />;
        }
    
        const message = isRandomAreas
          ? "There isn't enough data for the AI Agent to pick your weaknesses, so these are the most asked areas in CAT:"
          : "Verboace coach (AI Agent) picked these as your weak areas from your preparation data:";
    
        return (
          <div style={styles.weakAreasSection}>
            <Typography variant="body1" style={styles.weakAreasMessage}>{message}</Typography>
            <Typography variant="body1" style={styles.weakAreasMessage}> select the area if you want to take the test from a particular area</Typography>
        
            <div style={{display:"flex",justifyContent:"space-evenly",alignItems:"center"}}>
              {weakAreas.map((area) => (
                <Button
                  key={area.rcId}
                  
                  variant={selectedRcId === area.rcId ? "contained" : "outlined"}
                  onClick={() => setSelectedRcId(area.rcId)}
                  style={{margin:10}}
                >
                  {area.area} {selectedRcId === area.rcId && "✓"}
                </Button>
              ))}
            </div>
          </div>
        );
      };

    // useEffect(()=>{
    //     if(weakArea!=null && weakCategory!=null){
       
    //     }
    // },[weakArea,weakCategory])


    const onClose = async()=>{
        setModal(false)
        navigation.push('/testing',{data:dataTest})
    }


    const handleTabChange = (event, newValue) => {
      setActiveTab(newValue);
    };

    const switchToMentor = ()=>{
      setActiveTab(3);
    }

    const fetchPosts = async () => {
        try {
            const headers = {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            };

            const postData = {
                page: 1,
                limit: 25,
                onlyMine: false,
                bookmarks: false
            };

            const response = await axios.post(apiendpoint.path + "/v1/users/posts", postData, { headers });
            setData(response.data.posts);
            setLoading(false);
        } catch (error) {
            dispatch(logout(
            )); 
            navigation.replace('/')
            console.error('Error fetching posts:', error);
            setLoading(false);
        }
    };

    useEffect(() => {
       if(token && isLoggedIn){
            fetchWeakAreas();
            fetchPosts();
            fetchMentors()
          }   
 
    }, []);

    

    const ShimmerLoader = () => (
        <ContentLoader
            speed={2}
            width={600}
            height={160}
            viewBox="0 0 400 160"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
        >
            <rect x="0" y="0" rx="5" ry="5" width="400" height="160" />
        </ContentLoader>
    );
    return (
      <div className="app-container">
        {/* Left Column */}
        <div  className="column left-column" >
          {token && user && <TestResults token={token} user_id={user.user_id} />}
        </div>
  
        {/* Center Column */}
        <div className="column center-column">
          {!loading && user && user.verified && (
            <Button
              disabled={testloading}
              className="dashboard-button"
              style={{backgroundColor:"#7f388b",color:"white",fontWeight:"bold",fontSize:12}}
              onClick={() => navigation.push('/admin')}>
              Admin
            </Button>
          )}
  
          {renderWeakAreasSection()}
  
          {!loading && (
            <Button
              disabled={testloading}
              className="dashboard-button"
              style={{backgroundColor:"#7f388b",color:"white",fontWeight:"bold",fontSize:12}}
              onClick={() => fetchTest()}>
              {testloading ? "Loading..." : "Take the test"}
            </Button>
          )}
  
          <div className="tabs-container">
            <div className="tabs">
              <button 
                className={`tab ${activeTab === 0 ? 'active' : ''}`}
                onClick={() => handleTabChange(null, 0)}>
                Feed
              </button>
              <button 
                className={`tab ${activeTab === 1 ? 'active' : ''}`}
                onClick={() => handleTabChange(null, 1)}>
                Premium Forum
              </button>
              <button 
                className={`tab ${activeTab === 2 ? 'active' : ''}`}
                onClick={() => handleTabChange(null, 2)}>
                Buddy Room
              </button>
              <button 
                className={`tab ${activeTab === 3 ? 'active' : ''}`}
                onClick={() => handleTabChange(null, 3)}>
                Mentorship
              </button>
            </div>
          </div>
  
          {/* Tab Content */}
          <div className="tab-content" style={{display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column"}}>
            {activeTab === 0 && (
              loading ? (
                <div className="shimmer-container">
                  {[...Array(6)].map((_, i) => <ShimmerLoader  key={i} />)}
                </div>
              ) : (
                data && data.length > 0 ? (
                  data.map((post, index) => (
                    post.type === "test" ? 
                      <MyCard key={index} data={post} token={token} /> : 
                      <ContentCard key={index} data={post} token={token} />
                  ))
                ) : (
                  <Typography variant="h6">No posts available</Typography>
                )
              )
            )}
  
            {activeTab === 1 && (
              loading ? (
                <div className="shimmer-container">
                  {[...Array(6)].map((_, i) => <ShimmerLoader key={i} />)}
                </div>
              ) : (
                <QuoraActivity />
              )
            )}
  
            {activeTab === 2 && (
              loading ? (
                <div className="shimmer-container">
                  {[...Array(6)].map((_, i) => <ShimmerLoader key={i} />)}
                </div>
              ) : (
                <Buddy />
              )
            )}
  
            {activeTab === 3 && <MentorBooking />}
          </div>
        </div>
  
        {/* Right Column */}
        <div className="column right-column" >
          {user && user.verified && <MentorCard token={token} user={user} />}
          {mentors.length > 0 && (
            <TopperCarousel mentors={mentors} onclick={switchToMentor} />
          )}
          {!loading && <ReadingGame token={token} />}
          
          <Card className="timer-card">
            <Lottie animationData={rewardAnimation} className="lottie" />
            <Typography variant="h4">CAT Exam Countdown</Typography>
            <Countdown
              date={examDate}
              renderer={({ days, hours, minutes, seconds, completed }) => (
                <div className="countdown">
                  {completed ? (
                    <Typography variant="h6">The exam has started!</Typography>
                  ) : (
                    <Typography variant="h6" className="countdown-text">
                      {days} Days {hours} Hours {minutes} Minutes {seconds} Seconds
                    </Typography>
                  )}
                </div>
              )}
            />
          </Card>
  
          {token && <NotificationsBox token={token} />}
          {user && (
            <ProfileCard
              name={user.display_name}
              image={user.display_pic}
              title={user.email}
              tokens={user.tokens}
            />
          )}
        </div>
  
        {/* Modals */}
        <ConversationModal
          isOpen={modal}
          onClose={onClose}
          weakArea={weakArea}
          weakCategory={weakCategory}
        />
        <CustomModal
          isOpen={showInstallModal}
          onClose={() => setShowInstallModal(false)}
        />
      </div>
    );
  };
const styles = {
  appContainer: {
      display: 'flex',
      flexDirection: 'row', // default to row
      justifyContent: 'flex-start',
      alignItems: "flex-start",
      padding: '25px',
      marginTop: '0px',
      overflowY: 'auto',
      
      // Responsive adjustments
      '@media (max-width: 768px)': {
          flexDirection: 'column', // stack columns
          padding: '10px', // reduce padding
      }
  },
  leftColumn: {
      flex: 1,
      marginRight: '20px',

      // Responsive adjustments
      '@media (max-width: 768px)': {
          flex: '100%', // full width on mobile
          marginRight: '0',
          marginBottom: '10px', // spacing between stacked columns
      }
  },
  centerColumn: {
      flex: 2,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'center',

      // Responsive adjustments
      '@media (max-width: 768px)': {
          flex: '100%', // full width on mobile
          marginBottom: '10px', // spacing between stacked columns
      }
  },
  rightColumn: {
      flex: 1,
      marginLeft: '10px',
      
      // Responsive adjustments
      '@media (max-width: 768px)': {
          flex: '100%', // full width on mobile
          marginLeft: '0',
          marginBottom: '10px', // spacing between stacked columns
      }
  },
  reactButton: {
      marginTop: '10px',
      width: '90%', // responsive width
      maxWidth: '600px', // maximum width constraint
      backgroundColor: "#7f388b",
      fontWeight: "bold",
      padding: 4,
      color: "white",
      fontSize: 16,

      // Centering button in mobile view
      '@media (max-width: 768px)': {
          marginLeft: 'auto',
          marginRight: 'auto',
          width: '90%', // ensure it fits well on mobile
      }
  },
};


const mapStateToProps = (state) => ({
    isLoggedIn: state.auth.isLoggedIn,
    user: state.auth.user,
    token: state.auth.token
});

export default connect(mapStateToProps)(Dashboard);
