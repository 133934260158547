import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Container, Grid, Card, CardContent, Typography, CircularProgress, CardMedia, Box, Chip } from '@mui/material';
import { Link } from 'react-router-dom';
import { Book, Calendar, User, Clock } from 'lucide-react';
import HomeTop from '../TopHome';


const BlogListPageMain = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    axios.get('http://localhost:3000/v1/users/blogs/blogs')
      .then(response => {
        setPosts(response.data.posts);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching blog posts:', error);
        setError('Failed to load blog posts. Please try again later.');
        setLoading(false);
      });
  }, []);

  if (loading) {
    return (
      <Box 
        sx={{
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 2,
          bgcolor: '#fff'
        }}
      >
        <CircularProgress size={40} thickness={4} sx={{ color: '#2196f3' }} />
        <Typography variant="body1" color="textSecondary">
          Loading amazing content...
        </Typography>
      </Box>
    );
  }

  if (error) {
    return (
      <Box 
        sx={{
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          p: 3,
          bgcolor: '#f5f5f5'
        }}
      >
        <Typography variant="h6" color="error" align="center">
          {error}
        </Typography>
      </Box>
    );
  }

  return (
    <div style={{display:"flex",flexDirection:"column",backgroundColor:"white",minHeight:"100vh"}}>
        <HomeTop></HomeTop>
    <Box sx={{ bgcolor: '#fff', minHeight: '100%', py: 20 }}>
      <Container maxWidth="lg">
        <Typography 
          variant="h3" 
          component="h1" 
          sx={{
            mb: 4,
            fontWeight: 700,
            color: '#7f388b',
            textAlign: { xs: 'center', md: 'center' },
            fontSize: { xs: '2rem', md: '2.5rem' }
          }}
        >
          Latest Blogs
        </Typography>

        <Grid container spacing={4}>
          {posts.map((post, index) => (
            <Grid item xs={12} sm={6} md={4} key={post.id}>
            
                <Card 
                  sx={{ 
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    borderRadius: 2,
                    transition: 'transform 0.2s, box-shadow 0.2s',
                    '&:hover': {
                      transform: 'translateY(-4px)',
                      boxShadow: '0 12px 24px rgba(0,0,0,0.1)'
                    }
                  }}
                >
                  <CardMedia
                    component="img"
                    height="250"
                    image={post.featured_image_url || '/placeholder-image.jpg'}
                    alt={post.title}
                    sx={{ 
                      objectFit:'fill',
                      bgcolor: '#e0e0e0'
                    }}
                  />
                  <CardContent sx={{ flexGrow: 1, py: 1,px:3 }}>
                    <Box sx={{ mb: 2 }}>
                      <Chip 
                        label={post.author}
                        size="small"
                        sx={{
                          bgcolor:  '#e3f2fd',
                          color:  '#1976d2',
                          fontWeight: 600,
                          fontSize: '0.75rem'
                        }}
                      />
                    </Box>

                    <Typography 
                      variant="h6" 
                      sx={{
                        fontWeight: 600,
                        fontSize: '1.5rem',
                        mb: 1,
                        lineHeight: 1.4,
                        color: '#1a1a1a'
                      }}
                    >
                      {post.title}
                    </Typography>

                    <Typography 
                      variant="body2" 
                      sx={{
                        color: '#666',
                        mb: 1,
                        display: '-webkit-box',
                        WebkitLineClamp: 3,
                        fontSize:'0.9em',
                        WebkitBoxOrient: 'vertical',
                        overflow: 'hidden',
                        lineHeight: 1.6
                      }}
                    >
                      {post.meta_description}
                    </Typography>

                    <Box 
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        mt: 'auto'
                      }}
                    >
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Clock size={16} />
                        <Typography variant="caption" color="textSecondary">
                          {new Date(post.created_at).toLocaleDateString()}
                        </Typography>
                      </Box>

                      <Link 
                        to={`/blog/${post.slug}`} 
                        style={{ 
                          textDecoration: 'none',
                          display: 'flex',
                          alignItems: 'center',
                          gap: '0.5rem',
                          color: '#1976d2',
                          fontWeight: 600,
                          fontSize: '0.875rem'
                        }}
                      >
                        Read More
                        <Book size={16} />
                      </Link>
                    </Box>
                  </CardContent>
                </Card>
            </Grid>
          ))}
        </Grid>

        {posts.length === 0 && (
          <Box 
            sx={{
              textAlign: 'center',
              py: 8
            }}
          >
            <Typography variant="h6" color="textSecondary">
              No blog posts found
            </Typography>
          </Box>
        )}
      </Container>
    </Box>
    </div>
  );
};

export default BlogListPageMain;