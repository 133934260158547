import React, { useState } from 'react';
import Modal from 'react-modal';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const BookingModal = ({ isOpen, onClose, mentor, availableSlots, onBookSlot,userid }) => {
  const [step, setStep] = useState(1); // Tracks the current step (1: Date, 2: Time & Focus Areas)
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [helpOptions, setHelpOptions] = useState({
    test_preparation_help: false,
    sop_essay_help: false,
    interview_application_help: false,
  });

  const formatTime = (timeString) => {
    // Create a new Date object with a default date and the given time in "hh:mm a" format
    const [time, modifier] = timeString.split(' ');
    let [hours, minutes] = time.split(':');
    
    // Convert hours to 24-hour format if needed
    if (modifier === 'PM' && hours !== '12') {
      hours = parseInt(hours, 10) + 12;
    } else if (modifier === 'AM' && hours === '12') {
      hours = '00';
    }
  
    // Return formatted time as "HH:mm"
    return `${hours.toString().padStart(2, '0')}:${minutes}`;
  };

  const handleTimeClick = (time) => {
    const formattedTime = formatTime(time); // Convert "hh:mm a" to "HH:mm"
    setSelectedTime(formattedTime); // Save the formatted time
  };

  // Create a map of dates that have available slots
  const availableDates = availableSlots.reduce((acc, slot) => {
    const date = new Date(slot.slot_date).toISOString().split('T')[0];
    acc[date] = true;
    return acc;
  }, {});

  // Get available times for selected date
  const getAvailableTimesForDate = (date) => {
    const dateStr = date.toISOString().split('T')[0];
    return availableSlots
      .filter((slot) => slot.slot_date.split('T')[0] === dateStr)
      .map((slot) => slot.slot_time);
  };

  const handleDateClick = (date) => {
    setSelectedDate(date);
    setStep(2); // Move to the next step
    setSelectedTime(null); // Reset selected time
  };

  

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setHelpOptions((prev) => ({ ...prev, [name]: checked }));
  };

  const handleBookSlot = () => {
    const bookingDetails = {
      topper_id: mentor.user_id,
      student_id: userid,
      date: selectedDate.toISOString().split('T')[0],
      time: selectedTime,
      ...helpOptions,
    };
    onBookSlot(bookingDetails);
  };

  const tileDisabled = ({ date }) => {
    const dateStr = date.toISOString().split('T')[0];
    return !availableDates[dateStr];
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className="modal-dialog modal-lg"
      overlayClassName="modal-backdrop"
    >
      <div className="modal-content">
        {/* Header */}
        <div className="modal-header">
          <h5 className="modal-title">Book a Session with {mentor?.display_name}</h5>
          <button type="button" className="btn-close" onClick={onClose}></button>
        </div>

        {/* Body */}
        <div className="modal-body">
          {/* Step Indicator */}
          <div className="mb-4">
            <div className="d-flex justify-content-between align-items-center">
              <div className={`step-indicator ${step >= 1 ? 'active' : ''}`}>1. Select Date</div>
              <div className="step-connector"></div>
              <div className={`step-indicator ${step >= 2 ? 'active' : ''}`}>2. Select Time & Focus</div>
            </div>
          </div>

          {step === 1 && (
            <div>
              <h6>Select a Date</h6>
              <Calendar
                onChange={handleDateClick}
                value={selectedDate}
                tileDisabled={tileDisabled}
                minDate={new Date()}
              />
            </div>
          )}

          {step === 2 && (
            <div>
              {/* Time Slots */}
              <div className="mb-4">
                <h6>Select a Time Slot</h6>
                <div className="d-flex flex-wrap gap-2">
  {getAvailableTimesForDate(selectedDate).map((time) => {
    const formattedTime = formatTime(time); // Format each time slot
    return (
      <button
        key={formattedTime}
        className={`btn ${selectedTime === formattedTime ? 'btn-primary' : 'btn-outline-primary'}`}
        onClick={() => handleTimeClick(time)}
      >
        {time} {/* Display the original time */}
      </button>
    );
  })}
</div>
              </div>

              {/* Focus Areas */}
              <div>
                <h6>Select Focus Areas</h6>
                {['test_preparation_help', 'sop_essay_help', 'interview_application_help'].map(
                  (field) => (
                    <div className="form-check" key={field}>
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id={field}
                        name={field}
                        checked={helpOptions[field]}
                        onChange={handleCheckboxChange}
                      />
                      <label className="form-check-label" htmlFor={field}>
                        {field=="test_preparation_help"?"Test Preparation Help (Requires student to take 3 tests at least, else slot can't be booked)":field.replace(/_/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase())}
                      </label>
                    </div>
                  )
                )}
              </div>
            </div>
          )}
        </div>

        {/* Footer */}
        <div className="modal-footer">
          {step === 2 && (
            <button type="button" className="btn btn-secondary me-auto" onClick={() => setStep(1)}>
              Back
            </button>
          )}
          <button type="button" className="btn btn-secondary" onClick={onClose}>
            Close
          </button>
          {step === 2 && (
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleBookSlot}
              disabled={!selectedDate || !selectedTime}
            >
              Book Slot
            </button>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default BookingModal;
