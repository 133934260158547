import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Checkbox,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  Rating,
  Snackbar,
  Typography,
} from '@mui/material';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom';
import { connect } from 'react-redux';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Alert from '@mui/material/Alert';
import { apiendpoint } from '../endpoint';
import BookingModal from './bookSlot';
import MentorshipCallsList from './callsList';



const MentorBooking = ({token,user}) => {
  const [mentors, setMentors] = useState([]);
  const [selectedMentor, setSelectedMentor] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [availableDates, setAvailableDates] = useState({});
  const [availableTimeSlots, setAvailableTimeSlots] = useState([]);
  const [loading, setLoading] = useState(false);
  const [consent, setConsent] = useState(false);
  const [showTimeDialog, setShowTimeDialog] = useState(false);
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  const [meetLink, setMeetLink] = useState('');
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [helpOptions, setHelpOptions] = useState({
    testPreparation: false,
    sopEssay: false,
    interviewApplication: false,
  });

  const accessToken = token;

  const calls = [
    {
      callId: '123',
      topperName: 'John Doe',
      studentName: 'Jane Smith',
      scheduledAt: '2024-11-15T10:00:00.000Z',
      status: 'scheduled',
      meetLink: 'https://meet.google.com/xxx',
      topperpic: 'url-to-mentor-image',
      studentpic: 'url-to-student-image',
      topperreview: 4,
      studentreview: 5
    }
    // ... more calls
  ];

  useEffect(() => {
    fetchMentors();
    fetchMyCalls()
  }, []);

  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const fetchMentors = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${apiendpoint.path}/v1/auth/toppers`, {
        headers: {
          'Authorization': `Bearer ${accessToken}`,
        },
      });
      const data = await response.json();
      console.log(data)
      setMentors(data.data);
    } catch (error) {
      showSnackbar('Failed to load mentors', 'error');
    }
    setLoading(false);
  };

  const fetchMentorAvailableDates = async (mentorId) => {
    setLoading(true);
    try {
      const response = await fetch(`${apiendpoint.path}/users/mentorslots`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        },
        body: JSON.stringify({ mentor_id: mentorId }),
      });
      const data = await response.json();
      const availableDatesMap = {};
      data.slots.forEach(slot => {
        if (slot.status === 'available') {
          const date = slot.slot_date.split('T')[0];
          availableDatesMap[date] = true;
        }
      });
      setAvailableDates(availableDatesMap);
    } catch (error) {
      showSnackbar('Failed to fetch available dates', 'error');
    }
    setLoading(false);
  };

  const fetchTimeSlots = async (date) => {
    setLoading(true);
    try {
      const response = await fetch(`${apiendpoint.path}/users/mentorslots`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        },
        body: JSON.stringify({ mentor_id: selectedMentor.user_id }),
      });
      const data = await response.json();
      const formattedDate = date.toISOString().split('T')[0];
      const slots = data.slots
        .filter(slot => slot.slot_date.split('T')[0] === formattedDate && slot.status === 'available')
        .map(slot => slot.slot_time);
      setAvailableTimeSlots(slots);
      setShowTimeDialog(true);
    } catch (error) {
      showSnackbar('Failed to fetch time slots', 'error');
    }
    setLoading(false);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
const [currentSlots, setCurrentSlots] = useState([]);

// Update the handleMentorSelect function
const handleMentorSelect = async (mentor) => {
  setSelectedMentor(mentor);
  setLoading(true);
  try {
    const response = await fetch(`${apiendpoint.path}/v1/users/mentorslots`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`,
      },
      body: JSON.stringify({ mentor_id: mentor.user_id }),
    });
    const data = await response.json();
    setCurrentSlots(data.slots);
    setIsModalOpen(true);
  } catch (error) {
    showSnackbar('Failed to fetch mentor slots', 'error');
  }
  setLoading(false);
};

// Add this function to handle booking
const handleBookSlot = async (bookingDetails) => {
  setLoading(true);
  try {
    const response = await fetch(`${apiendpoint.path}/v1/users/schedule`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`,
      },
      body: JSON.stringify(bookingDetails),
    });
    const data = await response.json();
    setIsModalOpen(false);
    showSnackbar('Booking successful!', 'success');
  } catch (error) {
    showSnackbar('Booking failed', 'error');
  }
  setLoading(false);
};

  const initiatePayment = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${apiendpoint.path}/users/paymentinitiatementor`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          price: selectedMentor.current_price,
          topper_id: selectedMentor.user_id,
        }),
      });
      const data = await response.json();
      if (data.isFirstTime) {
        bookSlot();
      } else {
        // Initialize Razorpay payment
        const options = {
          key: 'rzp_live_CdI14KUbgMqAfP',
          amount: selectedMentor.current_price * 100,
          currency: 'INR',
          name: 'Verboace',
          description: 'Mentor Booking',
          order_id: JSON.parse(data.response).id,
          handler: (response) => {
            storePaymentDetails(response.razorpay_payment_id);
          },
        };
        const rzp = new window.Razorpay(options);
        rzp.open();
      }
    } catch (error) {
      showSnackbar('Payment initiation failed', 'error');
    }
    setLoading(false);
  };

  const storePaymentDetails = async (paymentId) => {
    setLoading(true);
    try {
      const response = await fetch(`${apiendpoint.path}/users/storePaymentDetailspremium`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          payment_id: paymentId,
          amount: selectedMentor.current_price,
        }),
      });
      if (response.ok) {
        bookSlot();
      }
    } catch (error) {
      showSnackbar('Failed to confirm payment', 'error');
    }
    setLoading(false);
  };

  const [calldata,setCalldata] = useState(null)

  const fetchMyCalls = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${apiendpoint.path}/v1/users/mycalls`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        }
      });
      if (response.ok) {
        const data = await response.json()
       setCalldata(data)
      }
    } catch (error) {
      showSnackbar('Failed to confirm payment', 'error');
    }
    setLoading(false);
  };

  const bookSlot = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${apiendpoint.path}/users/schedule`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          topper_id: selectedMentor.user_id,
          student_id: user.user_id,
          date: selectedDate.toISOString().split('T')[0],
          time: selectedTime,
          test_preparation_help: helpOptions.testPreparation,
          sop_essay_help: helpOptions.sopEssay,
          interview_application_help: helpOptions.interviewApplication,
        }),
      });
      const data = await response.json();
      setMeetLink(data.meet_link);
      setShowSuccessDialog(true);
    } catch (error) {
      showSnackbar('Booking failed', 'error');
    }
    setLoading(false);
  };

  const showSnackbar = (message, severity = 'info') => {
    setSnackbar({ open: true, message, severity });
  };



  const handleDateSelect = (e) => {
    const date = new Date(e.target.value);
    setSelectedDate(date);
    fetchTimeSlots(date);
  };

  const handleTimeSelect = (time) => {
    setSelectedTime(time);
    setShowTimeDialog(false);
  };

  return (
    <Container maxWidth="lg" style={{width:600}}>
        <BookingModal
  isOpen={isModalOpen}
  onClose={() => setIsModalOpen(false)}
  mentor={selectedMentor}
  availableSlots={currentSlots}
  onBookSlot={handleBookSlot}
  userid={user.user_id}
/>
      {/* Mentor list, date picker, and booking options remain similar */}
      <Box sx={{ py: 0 }}>
        <Typography variant="h4" gutterBottom style={{marginBottom:10, fontWeight:"bold",fontSize:20, textAlign:"center",color:"#7f388b",margin:10}}>
          Book a Mentorship Call
        </Typography>

        {loading && (
          <Box sx={{ display: 'flex', justifyContent: 'center', my: 4 }}>
            <CircularProgress />
          </Box>
        )}

        {/* Mentors List */}
        <Grid container spacing={3} sx={{ mb: 4 }}>
          {mentors.map((mentor) => (
          <Grid item xs={12} sm={6} md={6} key={mentor.user_id}>
          <Card
            sx={{
              cursor: 'pointer',
              border: selectedMentor?.user_id === mentor.user_id ? 2 : 0,
              borderColor: 'primary.main',
              height: 300, // Set a fixed height for the card
            }}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
            onClick={() => handleMentorSelect(mentor)}
          >
            <CardMedia
              component="img"
              style={{
                objectFit: "cover",
                borderRadius: "50%",
                width: 100,
                height: 100,
                justifyContent: "center",
                alignItems: "center",
                margin: 10,
              }}
              image={mentor.display_pic}
              alt={mentor.display_name}
            />
            <CardContent style={{ textAlign: "center" }}>
              <Typography variant="h4" style={{marginBottom:4}}>{mentor.display_name}</Typography>
              <Typography variant="body2" color="text.secondary">
                {mentor.bio}
              </Typography>
              <Rating value={4.5} readOnly size="small" sx={{ mt: 1 }} />
              <Typography variant="h6" sx={{ mt: 1 }}>
                ₹{mentor.current_price}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        
          ))}
        </Grid>
 </Box>
 <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbar.message}
      />

{calldata && <MentorshipCallsList data={calldata} />}
    </Container>
  );
};



const mapStateToProps = (state) => ({
    isLoggedIn: state.auth.isLoggedIn,
    user: state.auth.user,
    token:state.auth.token
});

export default connect(mapStateToProps)(MentorBooking);