import React, { useState, useEffect } from 'react';
import { Badge } from '@mui/material';
import { Button } from '@mui/material';
import { Card, CardContent, CircularProgress } from '@mui/material';
import { 
  Star, Clock, MessageCircle, Download, Users, 
  Brain, Wallet, Rocket
} from 'lucide-react';
import './MentorProfile.css';
import { apiendpoint } from '../endpoint';

const MentorProfile = ({ uid="NPRqmZpX2cNPv6baQMBzskmGE5K2" }) => {
  const [loading, setLoading] = useState(true);
  const [mentorData, setMentorData] = useState(null);

  useEffect(() => {
    const fetchMentorDetails = async () => {
      try {
        const response = await fetch(`${apiendpoint.path}/v1/users/mentordetailspublic`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ uid }),
        });
        
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        setMentorData(data.user);
      } catch (error) {
        console.error("Failed to fetch mentor details:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchMentorDetails();
  }, [uid]);

  if (loading || mentorData==null) {
    return (
      <div className="loading-indicator" style={{minHeight:100+"vh",justifyContent:"center",alignItems:"center",display:"flex",flexDirection:"column"}}>
        <CircularProgress />
        <p>Loading mentor details...</p>
      </div>
    );
  }

  return (
    <div className="container" style={{flexDirection:"column",padding:16}}>
      {/* Hero Section */}
      <div className="hero-section">
        <div className="hero-overlay"></div>
        <div className="hero-content">
          <div className="profile-image-container">
            <div className="profile-image">
              <img
                src={mentorData.display_pic}
                alt="Mentor"
              />
            </div>
          </div>
          
          <div className="profile-info">
            <div className="badge-container">
              <Badge className="badge badge-yellow">Top Mentor</Badge>
              <Badge className="badge badge-blue">Verified</Badge>
            </div>
            <h1 className="profile-name" style={{fontSize:30}}>{mentorData.display_name}</h1>
            <p className="profile-title" style={{color:"white",fontWeight:"bold",margin:10,fontSize:22}}>
              {mentorData.highlight}
            </p>
            <div className="skills-container" >
             <p style={{color:"white",fontSize:18}}>{mentorData.bio}</p>
            </div>
          </div>

          <div className="rating-section">
          <img src={mentorData.brand_logo} style={{backgroundColor:"white",borderRadius:20,marginBottom:20,width:120,height:"auto"}}></img>
            <div className="rating-stars">
              {[1, 2, 3, 4, 5].map((_, i) => (
                <Star key={i} className="star-icon" fill="currentColor" />
              ))}
              <span className="rating-value">{mentorData.average_rating}</span>
              <span className="rating-count">({mentorData.rating_count})</span>
            </div>
            <div className="pricing">
             <p className="price">₹{mentorData.current_price}/slot</p>
              <Button className="book-button" style={{color:"lightblue"}}>
                Checkout in app
              </Button>
            </div>
          </div>
        </div>
      </div>

      <div className="stats-grid" style={{margin:10}}>
        {[
          { 
            icon: <Clock className="stat-icon purple" />,
            label: "Sessions Completed", 
            value: mentorData.session_count,
            subtitle: "Total sessions" 
          },
          { 
            icon: <Users className="stat-icon green" />,
            label: "Unique Students Served", 
            value: mentorData.unique_students_served,
            subtitle: "Across programs" 
          },
          { 
            icon: <Star className="stat-icon yellow" />,
            label: "Average Rating", 
            value: mentorData.average_rating + "/5",
            subtitle: "Based on reviews" 
          },
          { 
            icon: <Clock className="stat-icon purple" />,
            label: "Served to students", 
            value: "< "+(parseInt(mentorData.session_count * 1/2))+" Hours",
            subtitle: "Typical range" 
          }
        ].map((stat, index) => (
          <Card key={index} className="stat-card">
            <CardContent className="stat-content">
              <div className="stat-icon-container">
                {stat.icon}
              </div>
              <div className="stat-value">{stat.value}</div>
              <div className="stat-label">{stat.label}</div>
              <div className="stat-subtitle">{stat.subtitle}</div>
            </CardContent>
          </Card>
        ))}
      </div>

      {/* Value Proposition Banner */}
      <Card className="value-prop-banner">
        <CardContent className="banner-content">
        <div className="platform-features">
        <div className="section-header">
          <h2 className="section-title">Definitely not another topmate, but a super app where you can read and take test with the help of AI agent. When you book a mentorship, your entire platform data is mailed to topper to give you best guidance to excel based on your current performance, strengths and weaknesses.</h2>
          <p className="section-subtitle">Experience a new way of learning and growing</p>
        </div>
        
        <div className="features-grid">
          {[
  {
    icon: <Brain className="feature-icon purple" />,
    title: "Read & Take Test with AI Guidance",
    description: "Excel with our intelligent Verboace Coach engineered for your success",
    highlight: "Adaptive AI technology"
  },
  {
    icon: <Users className="feature-icon yellow" />,
    title: "Book Mentorship Call with Topper",
    description: "Toppers get access to your preparation data to provide targeted guidance",
    highlight: "Data-driven mentoring"
  },
  {
    icon: <MessageCircle className="feature-icon red" />,
    title: "Post Query & Get Priority Response",
    description: "Receive personalized voice messages from verified toppers",
    highlight: "Instant expert support"
  },
  {
    icon: <Wallet className="feature-icon blue" />,
    title: "Bet on Your Own Progress to Earn",
    description: "First-ever Knowledge wallet system in EdTech space",
    highlight: "Incentivized learning"
  },
  {
    icon: <Users className="feature-icon green" />,
    title: "Connect with Peers Before MBA",
    description: "Innovative social platform to connect & compete with future leaders",
    highlight: "Pre-MBA networking"
  },
  {
    icon: <Rocket className="feature-icon green" />,
    title: "Student Community Driven",
    description: "We are always listening to you and shipping what you need to excel",
    highlight: "Super app by students"
  }
].map((feature, index) => (
            <Card key={index} className="feature-card">
              <CardContent className="feature-content">
                <div className="feature-header">
                  <div className="feature-icon-container">
                    {feature.icon}
                  </div>
                  <div className="feature-info">
                    <h3 className="feature-title">{feature.title}</h3>
                    <p className="feature-description">{feature.description}</p>
                    <Badge className="feature-highlight">
                      {feature.highlight}
                    </Badge>
                  </div>
                </div>
              </CardContent>
            </Card>
          ))}
        </div>
      </div>
        </CardContent>
      </Card>

      {/* App Download */}
      <div className="download-section">
        <div className="download-content">
          <h2 className="download-title">Get Started Today</h2>
          <p className="download-description">
            Download the Verboace app to access mentorship, resources, and community on the go
          </p>
          <div className="download-buttons">
            <Button className="download-button">
              <Download className="download-icon" />
              Download iOS App
            </Button>
            <Button className="download-button">
              <Download className="download-icon" />
              Download Android App
            </Button>
          </div>
          <div className="download-footer">
            Join 10,000+ students already learning on Verboace
          </div>
        </div>
      </div>
    </div>
  );
};

export default MentorProfile;
