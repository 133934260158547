import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardActions,
  Typography,
  Container,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Snackbar,
  CircularProgress,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import MuiAlert from '@mui/material/Alert';
import verboace from '../images/verboace.png'
import { useParams, useHistory } from 'react-router-dom/cjs/react-router-dom';
import { apiendpoint } from '../endpoint';
import axios from 'axios';

const GradientButton = styled(Button)(({ theme }) => ({
  background: 'linear-gradient(45deg, #FFD700 30%, #FFA500 90%)',
  border: 0,
  borderRadius: 3,
  boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
  color: 'white',
  height: 48,
  padding: '0 30px',
}));

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const CheckoutPage = () => {
  const { token } = useParams();
  const navigation = useHistory();
  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState(null); // null: no status, true: success, false: failure
  const [pricingData, setPricingData] = useState(null);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement('script');
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  async function displayRazorpay(data,plan) {
    const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js');

    if (!res) {
      alert('Razorpay SDK failed to load. Are you online?');
      return;
    }

    const options = {
      key: 'rzp_live_CdI14KUbgMqAfP', // Enter the Key ID generated from the Dashboard
      amount: data.response.amount,
      currency: data.response.currency,
      name: data.user.name,
      description: 'Subscription plan payment',
      order_id: data.response.id,
      handler: async function (response) {
        const data1 = {
          orderCreationId: data.response.id,
          razorpayPaymentId: response.razorpay_payment_id,
          razorpayOrderId: response.razorpay_order_id,
          razorpaySignature: response.razorpay_signature,
          amount:data.response.amount,
          plan:plan
        };

        const headers = {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        };

        try {
          const result = await axios.post(apiendpoint.path+'/v1/users/verifypaymentios', data1, { headers });
          if (result.data.msg === 'success') {
            setPaymentSuccess(true); // Mark payment as successful
          } else {
            setPaymentSuccess(false); // Mark payment as failed
          }
        } catch (error) {
          setPaymentSuccess(false); // Mark payment as failed in case of error
        }
      },
      prefill: {
        name: data.user.name,
        email: data.user.email,
      },
      theme: {
        color: '#7f388b',
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  }

  useEffect(() => {
    if (token === undefined) {
      navigation.replace('/');
    } else {
      const fetchPricing = async () => {
        try {
          const headers = {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          };
          const response = await axios.post(apiendpoint.path+'/v1/users/paymentbegin', {}, { headers });
          setPricingData(response.data);
        } catch (error) {
          console.error('Error fetching pricing:', error);
          navigation.replace('/');
        } finally {
          setLoading(false);
        }
      };

      fetchPricing();
    }
  }, [token]);

  const startPayment = async (price,plan) => {
    setLoading(true);
    try {
      const headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      };

      const response = await axios.post(apiendpoint.path+'/v1/users/paymentinitiateweb', { price }, { headers });
      displayRazorpay(response.data,plan);
    } catch (error) {
      console.error('Error starting payment:', error);
      setPaymentSuccess(false);
    } finally {
      setLoading(false);
    }
  };

  const plans = [
    {
      title: 'Premium Plan',
      price: 'price',
      features: [
        '200 RC Tests curated by 99.9+ percentilers',
        'Full web login access',
        'Unlimited VA Tests',
        '20 tokens for personal mentorship',
        'Unlimited query resolution on forum',
        'Personal Verboace Coach',
        'Full access to classroom and Analytics room',
      ],
    },
    {
      title: 'Pocket Friendly Plan',
      price: 'price1',
      features: [
        '30 RC Tests curated by 99.9+ percentilers',
        'Full Web login access',
        'Unlimited VA Tests',
        '5 tokens for personal mentorship',
        'Unlimited query resolution on forum',
        'Personal Verboace Coach',
        'Full access to classroom and Analytics room',
      ],
    },
  ];

  // Success and failure display
  if (paymentSuccess !== null) {
    return (
      <Container style={{ minHeight: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Card raised sx={{ p: 4, textAlign: 'center' }}>
          {paymentSuccess ? (
            <>
              <CheckCircleOutlineIcon color="success" sx={{ fontSize: 80 }} />
              <Typography variant="h4" sx={{ mt: 2 }}>
                Payment Successful!
              </Typography>
              <Typography variant="body1" sx={{ mt: 1 }}>
                Thank you for subscribing to Verboace. You can now access premium features.
              </Typography>
            </>
          ) : (
            <>
              <ErrorOutlineIcon color="error" sx={{ fontSize: 80 }} />
              <Typography variant="h4" sx={{ mt: 2 }}>
                Payment Failed!
              </Typography>
              <Typography variant="body1" sx={{ mt: 1 }}>
                Something went wrong with your payment. Please try again.
              </Typography>
            </>
          )}
          <Button variant="contained" color="primary" sx={{ mt: 4 }} onClick={() => navigation.replace('/')}>
            Go to Dashboard
          </Button>
        </Card>
      </Container>
    );
  }

  // Render the payment plans when there is no payment status
  return (
    <Container style={{minHeight:"100vh",display:"flex",flexDirection:"column",justifyContent:"space-evenly"}} maxWidth="md" sx={{ my: 4 }}>
      <Box sx={{ textAlign: 'center', mb: 4 }}>
        <a href='/'>
        <img src={verboace} alt="Logo" style={{ width: 100, height: 100 }} />
        </a>
        <Typography variant="h4" component="h1" gutterBottom>
          Welcome to Verboace
        </Typography>
        {pricingData && <Typography variant="h2" style={{margin:16}} color="text.primary">
         Hi {pricingData.name}
        </Typography>}
        <Typography variant="subtitle1" color="text.secondary">
          Choose your subscription plan
        </Typography>
      
      </Box>

      <Grid container spacing={3}>
        {plans.map((plan, index) => (
          <Grid item xs={12} md={6} key={index}>
            <Card raised>
              <CardContent>
                <Typography variant="h5" component="div" gutterBottom>
                  {plan.title}
                </Typography>
                <Typography variant="h4" color="text.secondary" gutterBottom>
                {pricingData?"₹"+pricingData[plan.price]:"Loading..."}
                </Typography>
                <List>
                  {plan.features.map((feature, idx) => (
                    <ListItem key={idx} disablePadding>
                      <ListItemIcon>
                        <CheckCircleOutlineIcon color="primary" />
                      </ListItemIcon>
                      <ListItemText primary={feature} />
                    </ListItem>
                  ))}
                </List>
              </CardContent>
              <CardActions>
                <GradientButton
                  fullWidth
                  onClick={() => startPayment(pricingData[plan.price],plan.price)}
                  disabled={loading}
                >
                  {loading ? <CircularProgress size={24} color="inherit" /> : 'JOIN NOW'}
                </GradientButton>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>

      <Box sx={{ mt: 4, textAlign: 'center' }}>
        <Typography variant="body2" color="text.secondary">
          By subscribing, you agree to our{' '}
          <a href="/terms-conditions">Terms and Conditions</a>,{' '}
          <a href="/privacy-policy">Privacy Policy</a>, and{' '}
          <a href="/refund-policy">Refund Policy</a>.
        </Typography>
      </Box>

      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
          Subscription successful! Welcome aboard.
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default CheckoutPage;
