import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Card } from '@mui/material';
import { ArrowBack, ArrowBackIosNew, ArrowForwardIos } from '@mui/icons-material';



const TopperCarousel = ({mentors,onclick}) => {
    const [currentIndex, setCurrentIndex] = React.useState(0);
    
const nextSlide = () => {
    setCurrentIndex((prevIndex) => 
      prevIndex === mentors.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => 
      prevIndex === 0 ? mentors.length - 1 : prevIndex - 1
    );
  };

  React.useEffect(() => {
    const timer = setInterval(nextSlide, 3000);
    return () => clearInterval(timer);
  }, []);

  // Custom CSS to be added to your stylesheet
  const styles = `
    .carousel-container {
      max-width: 400px;
      margin: 0 auto;
      position: relative;
      height: 300px;
    }

    .carousel-card {
      transition: all 0.3s ease;
      height: 100%;
    }

    .carousel-nav-button {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      background-color: rgba(255, 255, 255, 0.8);
      border: none;
      padding: 10px;
      cursor: pointer;
      z-index: 2;
    }

    .carousel-nav-button:hover {
      background-color: rgba(255, 255, 255, 1);
    }

    .carousel-nav-button.prev {
      left: 0;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }

    .carousel-nav-button.next {
      right: 0;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }

    .carousel-dots {
      position: absolute;
      bottom: 20px;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      gap: 8px;
    }

    .carousel-dot {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: #dee2e6;
      border: none;
      padding: 0;
      cursor: pointer;
    }

    .carousel-dot.active {
      background-color: #0d6efd;
    }

    .profile-image {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      object-fit: cover;
    }
  `;

  return (
    <Card
      variant="outlined"
      sx={{
        maxWidth: 300,
        width:300,
        margin: '20px auto',
        borderRadius: '16px',
        boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.1)'
      }}
    >
      <style>{styles}</style>
      <div className="carousel-container">
        <div className="h-100 d-flex align-items-center justify-content-center">
          <div className="carousel-card card shadow-sm p-4 text-center">
            <div className="d-flex justify-content-center mb-3">
              <img 
                src={mentors[currentIndex].display_pic} 
                alt={mentors[currentIndex].display_name}
                className="profile-image"
              />
            </div>
            
            <h3 className="h5 mb-2">
              {mentors[currentIndex].display_name}
            </h3>
            
            <p className="text-muted small mb-3" style={{fontFamily:"serif",fontSize:14,marginRight:8,marginLeft:8,fontWeight:"bold"}}>
              {mentors[currentIndex].highlight}
            </p>
            <div style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}}>
            <img src={mentors[currentIndex].brand_logo} style={{width:"auto",height:30,margin:10}}></img>
            <button onClick={onclick} className="btn btn-primary" style={{backgroundColor:"#7f388b",width:180,fontWeight:"bold"}}>
              Book Mentorship Slot
            </button>
            </div>
          </div>
        </div>

        <button 
          onClick={prevSlide}
          className="carousel-nav-button prev"
          aria-label="Previous slide"
        >
          <ArrowBackIosNew style={{margin:4}}/>
        </button>
        <button 
          onClick={nextSlide}
          className="carousel-nav-button next"
          aria-label="Next slide"
        >
          <ArrowForwardIos style={{margin:4}}/>
        </button>

        <div className="carousel-dots">
          {mentors.map((_, index) => (
            <button
              key={index}
              onClick={() => setCurrentIndex(index)}
              className={`carousel-dot ${index === currentIndex ? 'active' : ''}`}
              aria-label={`Go to slide ${index + 1}`}
            />
          ))}
        </div>
      </div>
    </Card>
  );
};

export default TopperCarousel;
