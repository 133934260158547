import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Typography,
  Button,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  IconButton,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { Star } from '@mui/icons-material';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { apiendpoint } from '../endpoint';

const MentorCard = ({ token }) => {
  const [mentor, setMentor] = useState(null);
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({ bio: '', highlight: '', current_price: '' });
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [slotsModalOpen, setSlotsModalOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedSlots, setSelectedSlots] = useState([]);

  // Fetch mentor details
  useEffect(() => {
    axios
      .get(apiendpoint.path + '/v1/users/mentordetails', {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })
      .then((response) => {
        if (response.data && response.data.user) {
          setMentor(response.data.user);
          setFormData({
            bio: response.data.user.bio,
            highlight: response.data.user.highlight,
            current_price: response.data.user.current_price,
          });
        }
      })
      .catch((error) => {
        console.error('Error fetching mentor details:', error);
      });
  }, [token]);

  // Handle input change
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Handle form submission
  const handleSubmit = () => {
    axios
      .post(
        apiendpoint.path + '/v1/users/updatementordetails',
        { bio: formData.bio, highlight: formData.highlight, price: formData.current_price },
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      )
      .then((response) => {
        console.log('Details updated successfully:', response.data);
        setOpen(false);
        setMentor((prev) => ({
          ...prev,
          bio: formData.bio,
          highlight: formData.highlight,
          current_price: formData.current_price,
        }));
      })
      .catch((error) => {
        console.error('Error updating details:', error);
      });
  };

  const generateTimeSlots = () => {
    const slots = [];
    const startTime = 9 * 60; // 9 AM in minutes
    const endTime = 22 * 60; // 10 PM in minutes
    for (let time = startTime; time < endTime; time += 30) {
      const hours = Math.floor(time / 60);
      const minutes = time % 60;
      slots.push(`${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`);
    }
    return slots;
  };

  const timeSlots = generateTimeSlots();

  // Handle slot selection
  const handleSlotChange = (slot) => {
    setSelectedSlots((prev) =>
      prev.includes(slot) ? prev.filter((s) => s !== slot) : [...prev, slot]
    );
  };

  // Submit selected slots
  const handleSlotSubmit = () => {
    axios
      .post(
        apiendpoint.path + '/v1/users/managementorslot',
        {
          slot_date: selectedDate.toISOString().split('T')[0],
          slot_times: selectedSlots,
        },
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      )
      .then((response) => {
        console.log('Slots updated successfully:', response.data);
        setSlotsModalOpen(false);
      })
      .catch((error) => {
        console.error('Error updating slots:', error);
      });
  };

  if (!mentor) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Card
        variant="outlined"
        sx={{
      
          margin: '20px auto',
          borderRadius: '16px',
          boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.1)',
          width:300
        }}
      >
        <CardMedia
          component="img"
          height="200"
          image={mentor.display_pic}
          alt={`${mentor.display_name}'s picture`}
          sx={{ borderRadius: '16px 16px 0 0' }}
        />
        <CardContent sx={{ padding: '16px' }}>
          <Typography variant="h5" gutterBottom>
            {mentor.display_name}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {mentor.bio}
          </Typography>
          <Box sx={{ marginTop: '16px' }}>
            <Typography variant="body1" color="primary.main">
              {mentor.highlight}
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '8px' }}>
              <Typography variant="body2" color="text.secondary" sx={{ marginRight: '8px' }}>
                Price: <strong>₹{mentor.current_price}</strong>
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Star color="primary" fontSize="small" />
                <Typography variant="body2" color="text.secondary" sx={{ marginLeft: '4px' }}>
                  {mentor.average_rating} ({mentor.rating_count} reviews)
                </Typography>
              </Box>
            </Box>
          </Box>
        </CardContent>
        <CardActions sx={{ padding: '16px', justifyContent: 'space-between' }}>
          <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
            Edit Details
          </Button>
          <Button variant="outlined" color="secondary" onClick={() => setSlotsModalOpen(true)}>
            Open Slots
          </Button>
        </CardActions>
      </Card>

      {/* Edit Details Modal */}
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="sm">
        <DialogTitle>
          Edit Details
          <IconButton
            aria-label="close"
            onClick={() => setOpen(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            margin="dense"
            label="Bio"
            name="bio"
            value={formData.bio}
            onChange={handleChange}
            multiline
            rows={4}
          />
          <TextField
            fullWidth
            margin="dense"
            label="Highlight"
            name="highlight"
            value={formData.highlight}
            onChange={handleChange}
          />
          <TextField
            fullWidth
            margin="dense"
            label="Price"
            name="current_price"
            value={formData.current_price}
            onChange={handleChange}
            type="number"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} variant="contained" color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={slotsModalOpen} onClose={() => setSlotsModalOpen(false)} fullWidth maxWidth="sm">
        <DialogTitle>
          Open Slots
          <IconButton
            aria-label="close"
            onClick={() => setSlotsModalOpen(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1" gutterBottom>
            Select a Date:
          </Typography>
          <Calendar value={selectedDate} onChange={setSelectedDate} />
          <Typography variant="body1" gutterBottom sx={{ marginTop: '16px' }}>
            Select Time Slots:
          </Typography>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '8px' }}>
            {timeSlots.map((slot) => (
              <FormControlLabel
                key={slot}
                control={
                  <Checkbox
                    checked={selectedSlots.includes(slot)}
                    onChange={() => handleSlotChange(slot)}
                  />
                }
                label={slot}
              />
            ))}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setSlotsModalOpen(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSlotSubmit} variant="contained" color="primary">
            Save Slots
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default MentorCard;
