import React, { useState, useEffect } from 'react';
import { Button, Form, Alert, Spinner } from 'react-bootstrap';
import { Snackbar } from '@mui/material';
import axios from 'axios';
import './Login.css';
import { encryptData } from './encryption';
import { loginSuccess } from '../authActions';
import { apiendpoint } from '../endpoint';
import { useHistory } from 'react-router-dom';
import google from '../images/google.png'
import { initializeApp } from 'firebase/app';
import { getAuth, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { useDispatch, connect } from 'react-redux';

// Initialize Firebase - Replace with your config
const firebaseConfig = {
  apiKey: "AIzaSyDWfu2RFm3HFxbmMfhM4R-WovqEb58LdCw",
  authDomain: "verboace-31212.firebaseapp.com",
  databaseURL: "https://verboace-31212-default-rtdb.firebaseio.com",
  projectId: "verboace-31212",
  storageBucket: "verboace-31212.firebasestorage.app",
  messagingSenderId: "346911359027",
  appId: "1:346911359027:web:fce9f98c7767cca19c6346",
  measurementId: "G-V1D6JB00C1"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();

const LoginComponent = ({ isLoggedIn }) => {
  const dispatch = useDispatch();
  const navigate = useHistory();
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [showOtpField, setShowOtpField] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [jwtoken, setJwToken] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '' });

  const handleGoogleLogin = async () => {
    try {
      setLoading(true);
      // Sign in with Google using Firebase
      const result = await signInWithPopup(auth, provider);
      
      // Get the ID token
      const idToken = await result.user.getIdToken();
      // console.log(idToken)
      // Send the token to your backend
      const response = await axios.post(`${apiendpoint.path}/v1/auth/googlelogin`, {
        token: idToken,
        // notification_token: '', // Add if you have this
        // deviceid: '12345', // Generate or get from device
        // ios: false, // Detect platform if needed
        // phone: 'NA'
      });

      const { accessToken, refreshToken, user } = response.data;
      
      // Store tokens and dispatch login success
      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('refreshToken', refreshToken);
      dispatch(loginSuccess(accessToken, user));
      
      setSuccess('Login successful!');
      navigate.replace('/home');
    } catch (error) {
      console.error('Login error:', error);
      setSnackbar({ 
        open: true, 
        message: 'Login failed. Please try again.' 
      });
    } finally {
      setLoading(false);
    }
  };
  
  // const googleLogin = useGoogleLogin({
  //   flow: 'auth-code',
  //   onSuccess: async (codeResponse) => {
  //     try {
  //       console.log(codeResponse)
  //       // Exchange the authorization code for tokens
  //       // const tokensResponse = await axios.post(`${apiendpoint.path}/v1/auth/google-login`, {
  //       //   code: codeResponse.code
  //       // });
        
  //       // const { accessToken, user } = tokensResponse.data;
  //       // localStorage.setItem('accessToken', accessToken);
  //       // dispatch(loginSuccess(accessToken, user));
  //       // navigate.replace('/home');
  //     } catch (error) {
  //       setSnackbar({ open: true, message: 'Google Login failed. Please try again.' });
  //     }
  //   },
  //   onError: () => setSnackbar({ open: true, message: 'Google Login failed. Please try again.' }),
  // });

  useEffect(() => {
    if (isLoggedIn) {
      navigate.replace('/home');
    }
  }, [isLoggedIn]);

  const validateEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    if (!validateEmail(email)) {
      setError('Please enter a valid email address.');
      return;
    }
    setLoading(true);
    setError('');
    try {
      const response = await axios.post(`${apiendpoint.path}/v1/auth/loginweb`, { email });
      setShowOtpField(true);
      setSuccess('OTP sent to your email.');
      setJwToken(response.data.jwtToken);
    } catch (error) {
      setSnackbar({ open: true, message: 'Failed to send OTP. Please try again.' });
    } finally {
      setLoading(false);
    }
  };

  const handleOtpSubmit = async (e) => {
    e.preventDefault();
    if (otp.length !== 6 || !/^\d+$/.test(otp)) {
      setError('Please enter a valid 6-digit OTP.');
      return;
    }
    setLoading(true);
    setError('');
    try {
      const response = await axios.post(`${apiendpoint.path}/v1/auth/otpvalidate`, { email, otp, token: jwtoken });
      setSuccess('Login successful!');
      const encryptedData = encryptData(response.data);
      localStorage.setItem('userData', encryptedData);
      localStorage.setItem('accessToken', response.data.accessToken);
      dispatch(loginSuccess(response.data.accessToken, response.data.user));
    } catch (error) {
      setSnackbar({ open: true, message: 'Invalid OTP. Please try again.' });
    } finally {
      setLoading(false);
    }
  };

  const handleSnackbarClose = () => setSnackbar({ ...snackbar, open: false });

  const handleGoogleSuccess = async (tokenResponse) => {
    try {
      console.log(tokenResponse)
      // const response = await axios.post(`${apiendpoint.path}/v1/auth/google-login`, {
      //   idToken: tokenResponse.credential,
      // });
      // const { accessToken, user } = response.data;
      // localStorage.setItem('accessToken', accessToken);
      // dispatch(loginSuccess(accessToken, user));
      // navigate.replace('/home');
    } catch (error) {
      setSnackbar({ open: true, message: 'Google Login failed. Please try again.' });
    }
  };

  // const googleLogin = useGoogleLogin({
  //   onSuccess: handleGoogleSuccess,
  //   onError: () => setSnackbar({ open: true, message: 'Google Login failed. Please try again.' }),
  // });

  return (
    <div className="login-page">
      <div className="login-container">
        <div className="header">
          <img
            src="http://storage.googleapis.com/assets_verboace/55837909.png"
            style={{ height: 100, width: 100 }}
            alt="Verboace"
            className="logo"
          />
          <div style={{ fontSize: 20, fontWeight: 'bold', marginBottom: 50 }}>
            Student login is available only for paid subscribers
          </div>
        </div>

        {error && <Alert variant="danger">{error}</Alert>}
        {success && <Alert variant="success">{success}</Alert>}
        {!showOtpField ? (
          <Form onSubmit={handleEmailSubmit}>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </Form.Group>
            <Button variant="primary" type="submit" disabled={loading} className="submit-button">
              {loading ? 'Sending...' : 'Send OTP'}
            </Button>
          </Form>
        ) : (
          <Form onSubmit={handleOtpSubmit}>
            <Form.Group controlId="formBasicOtp">
              <Form.Label>OTP</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter 6-digit OTP"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                required
                maxLength={6}
              />
            </Form.Group>
            <Button variant="primary" type="submit" disabled={loading} className="submit-button">
              {loading ? 'Verifying...' : 'Verify OTP'}
            </Button>
          </Form>
        )}
        <div style={{ marginTop: 20, textAlign: 'center',justifyContent:"center",alignItems:"center",display:"flex" }}>
        <button
  style={{
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "8px",
    padding: "10px 20px",
    backgroundColor: "#fff",
    color: "#5f6368",
    border: "1px solid #dadce0",
    borderRadius: "4px",
    fontSize: "16px",
    fontWeight: "500",
    cursor: "pointer",
    boxShadow: "0 1px 4px rgba(0, 0, 0, 0.2)",
    transition: "background-color 0.3s, box-shadow 0.3s",
  }}
  onClick={() => handleGoogleLogin()}
  className="google-login-button"
  onMouseEnter={(e) => (e.currentTarget.style.boxShadow = "0 2px 6px rgba(0, 0, 0, 0.3)")}
  onMouseLeave={(e) => (e.currentTarget.style.boxShadow = "0 1px 4px rgba(0, 0, 0, 0.2)")}
>
  <img
    src={google}
    alt="Google Logo"
    style={{ width: "20px", height: "20px" }}
  />
  Login with Google
</button>
        </div>
      </div>

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbar.message}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  isLoggedIn: state.auth.isLoggedIn,
  user: state.auth.user,
});

export default connect(mapStateToProps)(LoginComponent);
