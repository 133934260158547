import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import LoadingComponent from '../Comp/loadingComponent.jsx';
import './blog.css';
import HomeTop from '../TopHome.jsx';

const BlogReader = () => {
  const [blog, setBlog] = useState(null);
  const [loading, setLoading] = useState(true);
  const { slug } = useParams();

  const blockStyleFn = (block) => {
    switch (block) {
      case 'header-one':
        return 'heading-one';
      case 'header-two':
        return 'heading-two';
      case 'header-three':
        return 'heading-three';
      default:
        return null;
    }
  };

  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const response = await fetch(`http://localhost:3000/v1/users/blogs/blog?slug=${slug}`);
        const data = await response.json();
        setBlog(data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching blog:', error);
        setLoading(false);
      }
    };

    fetchBlog();
  }, [slug]);

  const renderContent = (content) => {
    if (!content?.blocks) return null;

    return content.blocks.map((block, index) => {
        const blockStyle = blockStyleFn(block);
      switch (block.type) {
            case 'unstyled':
              return (
                <p key={block.key} className={`prose my-4 ${blockStyle}`}>
                  {block.text}
                </p>
              );
            case 'header-one':
            case 'header-two':
            case 'header-three':
              return (
                <h2 key={block.key} style={{fontSize:"2.5rem",textAlign:"center"}} className={`prose my-1 ${blockStyle}`}>
                  {block.text}
                </h2>
              );
        case 'atomic':
          const entity = content.entityMap[block.entityRanges[0]?.key];
          if (entity?.type === 'IMAGE') {
            return (
              <div key={block.key} className="my-8">
                <img 
                  src={entity.data.src} 
                  alt="Blog content"
                  className="featured-image shadow-lg"
                  loading="lazy"
                />
              </div>
            );
          }
          return null;

        case 'unstyled':
          if (!block.text) return <div key={block.key} className="h-4" />;

          let text = block.text;
          if (block.inlineStyleRanges?.length) {
            block.inlineStyleRanges.forEach(range => {
              if (range.style === 'BOLD') {
                const before = text.slice(0, range.offset);
                const bold = text.slice(range.offset, range.offset + range.length);
                const after = text.slice(range.offset + range.length);
                text = (
                  <>
                    {before}
                    <strong>{bold}</strong>
                    {after}
                  </>
                );
              }
            });
          }

          if (block.entityRanges?.length) {
            const entity = content.entityMap[block.entityRanges[0].key];
            if (entity?.type === 'LINK') {
              return (
                <p key={block.key} className="prose my-4">
                  <a 
                    href={entity.data.url} 
                    target="_blank" 
                    rel="noopener noreferrer"
                  >
                    {text}
                  </a>
                </p>
              );
            }
          }

          return (
            <p key={block.key} className="prose my-4">
              {text}
            </p>
          );

        default:
          return null;
      }
    });
  };

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <LoadingComponent />
      </div>
    );
  }

  if (!blog) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <p className="text-xl text-gray-600">Blog not found</p>
      </div>
    );
  }

  return (
    <div style={{display:"flex",padding:16,backgroundColor:"white",flexDirection:"column"}}>
        <HomeTop></HomeTop>
    <article style={{marginTop:10+"%"}}>
      <header>
        <h1 style={{fontSize:"4rem",fontWeight:"bold",margin:0}}>{blog.title}</h1>
        <div className="flex items-center mb-6">
          <img src={blog.author_image} alt={blog.author} style={{width:30,height:30,borderRadius:50+"%",marginTop:20}} />
          <div className="author-info">
            <p className="font-medium">{blog.author}</p>
            <div className="flex items-center text-sm text-gray-600">
              <time dateTime={blog.created_at}>
                {new Date(blog.created_at).toLocaleDateString('en-US', {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                })}
              </time>
              <span> • </span>
              <span> {blog.reading_time} min read</span>
            </div>
          </div>
        </div>
        {/* {blog.featured_image_url && (
          <img
            src={blog.featured_image_url}
            alt={blog.title}
            className="featured-image"
          />
        )} */}
        <div className="tags">
          {blog.tags?.map((tag) => (
            <span key={tag}>{tag}</span>
          ))}
        </div>
      </header>

      <div className="prose">{renderContent(blog.content)}</div>

      <footer>
        <div className="flex items-center justify-between">
          <span>{blog.view_count} views</span>
          {blog.canonical_url && (
            <a 
              href={blog.canonical_url}
              target="_blank"
              rel="noopener noreferrer"
            >
              Original source
            </a>
          )}
        </div>
      </footer>
    </article>
    </div>
  );
};

export default BlogReader;
