import React, { useState, useEffect } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, PieChart, Pie, Cell, BarChart, Bar } from 'recharts';
import GaugeChart from 'react-gauge-chart';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { ArrowUpRight, ArrowDownLeft } from 'lucide-react';
import './student.css';
import LoadingComponent from './loadingComponent.jsx';
import { apiendpoint } from '../endpoint';
import { useParams } from 'react-router-dom';

const StudentPerformanceDashboard = ({ token,user }) => {
    const { id } = useParams();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  const history = useHistory()
  const COLORS = ['#FF6347', '#FFD700', '#FFA500', '#32CD32'];

  useEffect(() => {
    if(user && user.user_id && token){
    const fetchData = async () => {
      setLoading(true);
    
      try {
        const response = await fetch(apiendpoint.path+'/v1/users/studentdetailsformentor', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization':'Bearer '+token
          },
          body: JSON.stringify({ call_id: id }),
        });
        if (!response.ok) {  // Check if the response status is not 200
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
        const result = await response.json();
        setData(result);
      } catch (error) {
        console.error('Error fetching data:', error);
        history.push('/')
      } finally {
        setLoading(false);
      }
    };

    fetchData();
}
  }, [user]);

  if (loading) {
    return <LoadingComponent/>;
  }

  if (!data) {
    return <div>Error loading data</div>;
  }

  const createLineChartData = (dataArray) => dataArray?.map(item => ({
    name: item.difficulty,
    attempted: item.attempted_count,
    correct: item.correct_count,
  }));

  const createScoreRangeData = (dataArray) => {
    const totalUsers = dataArray?.reduce((sum, item) => sum + Number(item.user_count), 0);
    return dataArray?.map(item => ({
      name: item.range,
      users: Number(item.user_count),
      percentage: ((Number(item.user_count) / totalUsers) * 100).toFixed(1),
    }));
  };

  const createPercentageChartData = (dataArray) => dataArray?.map(item => ({
    name: item.category || item.lower,
    attempted: item.attempted_count,
    correct: item.correct_count,
    percentage: item.percentage,
  }));

  const createAccuracyChartData = (myAccuracyData, averageAccuracyData) => myAccuracyData?.map((item, index) => ({
    dataPointText: "Test" + (index + 1),
    myAccuracy: item.value,
    averageAccuracy: averageAccuracyData[index]?.value,
  }));

  // Ensure data availability before processing
  const responsesByDifficultyData = data.responsesByDifficulty ? createLineChartData(data.responsesByDifficulty) : [];
  const userScoresData = data.userScores ? createScoreRangeData(data.userScores) : [];
  const userCategoryPercentagesData = data.userCategoryPercentages ? createPercentageChartData(data.userCategoryPercentages) : [];
  const userAreaPercentagesData = data.userAreaPercentages ? createPercentageChartData(data.userAreaPercentages) : [];
  const accuracyChartData = (data.accuracyComparison?.myAccuracy && data.accuracyComparison.averageAccuracy)
    ? createAccuracyChartData(data.accuracyComparison.myAccuracy, data.accuracyComparison.averageAccuracy)
    : [];

  return (
    <div className="container">
    <div className="dashboard">
    <div className="user-info" style={{width:85+"vw"}}>
        <img src={data.user.display_pic} alt={data.user.name} />
        <div >
          <h3>{data.user.display_name}</h3>
          <p className="email">{data.user.email}</p>
        </div>
      </div>

      {/* Percentile Prediction */}
      <div className="percentile-prediction" style={{justifyContent:"center",alignItems:"center",display:"flex",flexDirection:"column", width:85+"vw"}}>
        <p>{data.percentilePrediction}%ile</p>
        <p className="percentile-text">Estimated Percentile</p>
        <GaugeChart
            id="gauge-chart"
            style={{height:180,width:400}}
            nrOfLevels={20}
            colors={['#EA4228', '#F5CD19', '#5BE12C']}
            arcWidth={0.2}
            animate={true}
            percent={(data.percentilePrediction - 80) / 20}
            arcPadding={0.02}
            formatTextValue={() => ''}
          />
      </div>
      <h1>Student Performance Dashboard</h1>

      {/* Grid for charts */}
      <div className="grid">
        {/* Responses by Difficulty Chart */}
        <div className="chart-card">
<h4>Responses Correct by Difficulty</h4>
<ResponsiveContainer width="100%" height={300}>
  <BarChart data={responsesByDifficultyData}>
    <XAxis dataKey="name" />
    <YAxis />
    <CartesianGrid strokeDasharray="3 3" />
    <Tooltip />
    <Legend />
    <Bar
      dataKey="attempted"
      fill={"#7f388b"}
    />
    <Bar
      dataKey="correct"
      fill={"green"}
    />
  </BarChart>
</ResponsiveContainer>
</div>

        {/* Score Range Chart */}
        <div className="chart-card">
        <h4>Score range disribution across tests</h4>
<ResponsiveContainer width="100%" height={300}>
  <PieChart>
    <Tooltip />
    <Legend />
    <Pie
      data={userScoresData}
      dataKey="users"
      nameKey="name"
      cx="50%"
      cy="50%"
      outerRadius={100}
      label
    >
      {userScoresData.map((entry, index) => (
        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
      ))}
    </Pie>
  </PieChart>
</ResponsiveContainer>
</div>

        {/* Category-wise Percentages Chart */}
        <div className="chart-card">
        <h4>Category Wise Accuracy Comparision</h4>
<ResponsiveContainer width="100%" height={300}>
  <BarChart data={userCategoryPercentagesData} layout="vertical">
    <CartesianGrid strokeDasharray="3 3" />
    <XAxis type="number" />
    <YAxis type="category" dataKey="name" />
    <Tooltip />
    <Legend />
    <Bar dataKey="percentage">
      {userCategoryPercentagesData.map((entry, index) => {
        const color =
          entry.percentage > 90
            ? COLORS[3] // Green
            : entry.percentage >= 50
            ? COLORS[2] // Orange
            : COLORS[0]; // Red
        return <Cell key={`cell-${index}`} fill={color} />;
      })}
    </Bar>
  </BarChart>
</ResponsiveContainer>
</div>
</div>
        {/* Area-wise Percentages Chart */}
        <div className="chart-card" style={{width:"85vw"}}>
        <h3>Area Wise Accuracy Comparision</h3>
<ResponsiveContainer width="100%" height={300}>
  <BarChart data={userAreaPercentagesData}>
    <CartesianGrid strokeDasharray="3 3" />
    <XAxis dataKey="name" />
    <YAxis />
    <Tooltip />
    <Legend />
    <Bar dataKey="percentage">
      {userAreaPercentagesData.map((entry, index) => {
        // Determine color based on percentage accuracy
        const color =
          entry.percentage > 90
            ? COLORS[3] // Green
            : entry.percentage >= 50
            ? COLORS[2] // Orange
            : COLORS[0]; // Red
        return <Cell key={`cell-${index}`} fill={color} />;
      })}
    </Bar>
  </BarChart>
</ResponsiveContainer>
</div>
<div className="grid">

      {/* Accuracy Comparison */}
      <div className="accuracy-comparison" style={{ flexDirection: "row", display: "flex" }}>
<div className="accuracy-card">
  <h3>My Accuracy in last 5 tests</h3>
  <ResponsiveContainer width="100%" height={300}>
    <LineChart data={accuracyChartData}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="dataPointText" />
      <YAxis />
      <Tooltip />
      <Legend />
      <Line type="monotone" dataKey="myAccuracy" stroke="#82ca9d" strokeWidth={2} />
      </LineChart>
  </ResponsiveContainer>

</div>




    </div>

    <div className="accuracy-comparison" style={{ flexDirection: "row", display: "flex" }}>
<div className="accuracy-card">
  <h3>Accuracy Other's Average</h3>
  <ResponsiveContainer width="100%" height={300}>
    <LineChart data={accuracyChartData}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="dataPointText" />
      <YAxis />
      <Tooltip />
      <Legend />
      <Line type="monotone" dataKey="averageAccuracy" stroke="#8884d8" strokeWidth={2}  /> {/* Dotted line */}
    </LineChart>
  </ResponsiveContainer>

</div>




    </div>
    </div>
    <div className="chart-card" style={{width:"85vw"}}>
  <h3>Advise based on current performance and data</h3>
  {data.advise}
      </div>
      {/* Student Information */}
   
    </div>
    
  </div>
  );
};


const mapStateToProps = (state) => ({
    isLoggedIn: state.auth.isLoggedIn,
    user: state.auth.user,
    token: state.auth.token
});

export default connect(mapStateToProps)(StudentPerformanceDashboard);