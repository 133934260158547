import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Card, CircularProgress, Typography, LinearProgress, Box, Dialog, DialogTitle, DialogContent, DialogActions, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { apiendpoint } from '../endpoint';

const ReadingGame = ({ token }) => {
  const [gameState, setGameState] = useState('initial');
  const [passage, setPassage] = useState('');
  const [startTime, setStartTime] = useState(null);
  const [results, setResults] = useState(null);
  const [loading, setLoading] = useState(false);
  const [rcarea, setRcArea] = useState('');
  const [openResultsDialog, setOpenResultsDialog] = useState(false);
  const [gameResults, setGameResults] = useState([]);

  const fetchPassage = async () => {
    setLoading(true);
    try {
      const response = await axios.post(`${apiendpoint.path}/v1/users/getgame`, {}, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      setPassage(response.data.passage);
      setGameState('reading');
      setStartTime(Date.now());
      setRcArea(response.data.rc_area);
    } catch (error) {
      console.error('Error fetching passage:', error);
    }
    setLoading(false);
  };

  const submitResults = async () => {
    setLoading(true);
    const endTime = Date.now();
    const timeTaken = Math.round((endTime - startTime) / 1000);
    const wordsCount = passage.split(/\s+/).length;

    try {
      const response = await axios.post(
        `${apiendpoint.path}/v1/users/gameresults`,
        {
          rc_area: 'reading_comprehension',
          words_count: wordsCount,
          time_in_seconds: timeTaken,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setResults(response.data);
      setGameState('results');
    } catch (error) {
      console.error('Error submitting results:', error);
    }
    setLoading(false);
  };

  const fetchGameResults = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${apiendpoint.path}/v1/users/wordspm`,
        {},
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setGameResults(response.data.user_wpm_by_area);
      setOpenResultsDialog(true);
    } catch (error) {
      console.error('Error fetching game results:', error);
    }
    setLoading(false);
  };

  const CircularProgressWithLabel = ({ value, color, size = 100, thickness = 4 }) => {
    return (
      <Box position="relative" display="inline-flex" >
        <CircularProgress variant="determinate" value={value} size={size} thickness={thickness} style={{ color }} />
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography variant="caption" component="div" color="textSecondary">
            {`${Math.round(value)} WPM`}
          </Typography>
        </Box>
      </Box>
    );
  };

  const renderResults = () => {
    if (!results) return null;
  
    const beforeWPM = results.rc_area_average_wpm_before || 0;
    const afterWPM = results.rc_area_average_wpm_after || 0;
    const overallWPM = results.overall_average_wpm || 0;
    const improvement = afterWPM - beforeWPM;
    const improvementPercentage = beforeWPM ? ((afterWPM - beforeWPM) / beforeWPM * 100).toFixed(2) : 'N/A';
  
    const getColor = (before, after) => {
      if (after > before) return 'green';
      if (after < before) return 'red';
      return '#7f388b';
    };
  
    return (
      <div style={{ width: '300px', margin: '0 auto', textAlign: 'center' }}>
        <Typography variant="h6" gutterBottom style={{ fontSize: '16px', marginBottom: '8px' }}>
          <strong>{rcarea} Area</strong> Insights
        </Typography>
        <Typography style={{ fontSize: '12px', marginBottom: '16px', width:280,padding:8 }}>
          Words per minute ability in reading comprehension reveals more about your strengths and weaknesses. Focus on improving it over time to achieve better scores and ranks.
        </Typography>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Box textAlign="center" margin={2}>
            <Typography margin={1} style={{ fontSize: '14px' }}>Before</Typography>
            <CircularProgressWithLabel value={beforeWPM} color={getColor(beforeWPM, afterWPM)} />
            <Typography style={{ fontSize: '12px' }}>{rcarea} {beforeWPM} WPM</Typography>
          </Box>
          <Box textAlign="center" margin={2}>
            <Typography margin={1} style={{ fontSize: '14px' }}>After</Typography>
            <CircularProgressWithLabel value={afterWPM} color={getColor(beforeWPM, afterWPM)} />
            <Typography style={{ fontSize: '12px' }}>{rcarea} {afterWPM} WPM</Typography>
          </Box>
          <Box textAlign="center" margin={2}>
            <Typography margin={1} style={{ fontSize: '14px' }}>Overall Average</Typography>
            <CircularProgressWithLabel value={overallWPM} color="#7f388b" />
            <Typography style={{ fontSize: '12px' }}>{overallWPM} WPM</Typography>
          </Box>
        </Box>
        <Typography
          variant="h6"
          style={{
            fontSize: '14px',
            margin: '8px 0',
            color: improvement >= 0 ? 'green' : 'red',
          }}
        >
          Improvement: {improvement} WPM ({improvementPercentage}%)
        </Typography>
        <Button
          onClick={() => setGameState('initial')}
          variant="contained"
          color="primary"
          style={{
            backgroundColor: "#7f388b",
            fontSize: '12px',
            fontWeight: '600',
            marginTop: '10px',
          }}
        >
          Play Again
        </Button>
      </div>
    );
  };
  

  const renderContent = () => {
    switch (gameState) {
      case 'initial':
        return (
          <div style={{display:"flex",flexDirection:"row",justifyContent:"space-evenly",alignItems:"center",borderRadius:10}}>
            <Button 
              onClick={fetchPassage} 
              disabled={loading}
              variant="contained" 
              color="primary"
              style={{backgroundColor:"#7f388b", fontSize:10, fontWeight:"600", marginRight: 10}}
            >
              Reading Game
            </Button>
            <Button 
              onClick={fetchGameResults} 
              disabled={loading}
              variant="contained" 
              color="secondary" 
              style={{backgroundColor:"#7f388b", fontSize:10, fontWeight:"600", marginRight: 10}} style={{backgroundColor:"#7f388b", fontSize:12, fontWeight:"600"}}
            >
              Game Results
            </Button>
          </div>
        );
      case 'reading':
        return (
          <>
            <Typography variant="body1" style={{ marginBottom: '20px', textAlign: 'left', padding:16, fontSize:14 }}>
              {passage}
            </Typography>
            <Button 
              onClick={submitResults} 
              disabled={loading}
              variant="contained" 
              color="primary"
              style={{backgroundColor:"#7f388b", fontSize:14, fontWeight:"600"}}
            >
              I'm Done Reading
            </Button>
          </>
        );
      case 'results':
        return renderResults();
      default:
        return null;
    }
  };

  return (
    <>
      <Card style={{ padding: '20px', marginTop: '20px', width: '300px', margin: '20px auto',  display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center" }} elevation={5}>
        <Typography variant="h5" gutterBottom style={{marginBottom:20, color:"#7f388b"}}>PLAY GAME</Typography>
        {loading && <CircularProgress style={{ marginBottom: '20px' }} />}
        {renderContent()}
      </Card>
      <Dialog open={openResultsDialog} onClose={() => setOpenResultsDialog(false)} maxWidth="md" fullWidth>
        <DialogTitle style={{textAlign: 'center', color: '#7f388b'}}>Game Results Ranking</DialogTitle>
        <DialogContent>
          {gameResults.length === 0 ? (
            <Typography align="center">No data available yet.</Typography>
          ) : (
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={{fontWeight: 'bold'}}>RC Area</TableCell>
                    <TableCell style={{fontWeight: 'bold'}}>Your WPM</TableCell>
                    <TableCell style={{fontWeight: 'bold'}}>Overall WPM</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {gameResults.map((result, index) => (
                    <TableRow key={index}>
                      <TableCell>{result.rc_area}</TableCell>
                      <TableCell style={{color: result.user_average_wpm >= result.overall_average_wpm ? 'green' : 'red'}}>
                        {result.user_average_wpm}
                      </TableCell>
                      <TableCell>{result.overall_average_wpm}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenResultsDialog(false)} style={{backgroundColor: '#7f388b', color: 'white'}}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ReadingGame;