import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { apiendpoint } from './endpoint';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

const ITEMS_PER_PAGE = 10;

const QuoraActivity = ({ isLoggedIn, user, token }) => {
  const navigation = useHistory();
  const [question, setQuestion] = useState('');
  const [isAnonymous, setIsAnonymous] = useState(false);
  const [questionsAndAnswers, setQuestionsAndAnswers] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [postingQuestion, setPostingQuestion] = useState(false);


  useEffect(() => {
    if (!isLoggedIn) {
      navigation.replace("/");
    }
  }, [isLoggedIn, navigation]);

  useEffect(() => {
    fetchQuestionsAndAnswers();
  }, []);

  const fetchQuestionsAndAnswers = async (refreshing = false) => {
    if (loading || (!hasMore && !refreshing)) return;

    setLoading(true);
    try {
      const response = await axios.get(`${apiendpoint.path}/v1/users/getq`, {
        params: { limit: ITEMS_PER_PAGE, page: refreshing ? 1 : page },
        headers: { Authorization: `Bearer ${token}` }
      });

      const data = response.data;
      if (refreshing) {
        setQuestionsAndAnswers(data.questionsAndAnswers);
        setPage(2);
      } else {
        setQuestionsAndAnswers(prev => [...prev, ...data.questionsAndAnswers]);
        setPage(prev => prev + 1);
      }
      
      setHasMore(data.questionsAndAnswers.length === ITEMS_PER_PAGE);
    } catch (error) {
      console.error('Error fetching questions and answers:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleRefresh = useCallback(() => {
    setHasMore(true);
    fetchQuestionsAndAnswers(true);
  }, []);

  const postQuestion = async () => {
    if (!question.trim()) {
      return;
    }

    setPostingQuestion(true);
    try {
      await axios.post(`${apiendpoint.path}/v1/users/postquery`, {
        question: question.trim(),
        anonymous: isAnonymous,
      }, {
        headers: { Authorization: `Bearer ${token}` }
      });

      setQuestion('');
      setIsAnonymous(false);
      handleRefresh();
    } catch (error) {
      console.error('Error posting question:', error);
    } finally {
      setPostingQuestion(false);
    }
  };

  return (
    <div className="quora-activity" style={{minHeight:100+"vh",width:600}}>
      <h1 className="quora-title"> Verboace Premium Forum</h1>
      
      <div className="question-card">
        <div className="question-input">
          <textarea
            value={question}
            maxLength={400}
            onChange={(e) => setQuestion(e.target.value)}
            placeholder="What is your query or concern?"
            rows={3}
          />
          <div className="anonymous-switch">
            <label>
              <input 
                type="checkbox" 
                checked={isAnonymous} 
                onChange={(e) => setIsAnonymous(e.target.checked)} 
              />
              Post Anonymously
            </label>
          </div>
          <button 
            className="post-button"
            onClick={postQuestion}
            disabled={postingQuestion}
          >
            {postingQuestion ? 'Posting...' : 'Add Question'}
          </button>
        </div>
      </div>
      
      <ul className="question-list">
        {questionsAndAnswers.map((item) => (
          (item.answered) ?
          <QuestionItem key={item.query_id} item={item} token={token} />
          : null
        ))}
      </ul>
      
      {loading && <p className="loading-text">Loading...</p>}
      {!loading && hasMore && (
        <button className="load-more-button" onClick={() => fetchQuestionsAndAnswers()}>
          Load More
        </button>
      )}
    </div>
  );
};

const QuestionItem = ({ item, token }) => {
  const [hasUpvoted, setHasUpvoted] = useState(false);
  const [totalUpvotes, setTotalUpvotes] = useState(item.upvotes);
  const [loadingUpvote, setLoadingUpvote] = useState(false);
  const [playing, setPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);

  const [loading, setLoading] = useState(true);



  const audioRef = useRef(new Audio());
  const intervalRef = useRef();

  const initializeAudio = () => {
    if (item.answer_voice_link) {
      const audio = audioRef.current;
      audio.src = `https://storage.googleapis.com/assets_verboace/${item.answer_voice_link}`;
      
      audio.onloadedmetadata = () => {
        setDuration(audio.duration);
        setLoading(false);
      };

      audio.onerror = () => {
        console.error('Error loading audio');
        setLoading(false);
      };
    }
  };

  const togglePlayPause = () => {
    if (playing) {
      audioRef.current.pause();
      clearInterval(intervalRef.current);
    } else {
      audioRef.current.play();
      intervalRef.current = setInterval(() => {
        setCurrentTime(audioRef.current.currentTime);
      }, 1000);
    }
    setPlaying(!playing);
  };

  const handleSeek = (event, newValue) => {
    clearInterval(intervalRef.current);
    audioRef.current.currentTime = newValue;
    setCurrentTime(newValue);
    if (playing) {
      intervalRef.current = setInterval(() => {
        setCurrentTime(audioRef.current.currentTime);
      }, 1000);
    }
  };

  useEffect(() => {
    audioRef.current.addEventListener('ended', () => {
      setPlaying(false);
      setCurrentTime(0);
      clearInterval(intervalRef.current);
    });

    return () => {
      audioRef.current.removeEventListener('ended', () => {
        setPlaying(false);
        setCurrentTime(0);
        clearInterval(intervalRef.current);
      });
    };
  }, []);

  useEffect(() => {
    fetchUpvoteStatus();
    initializeAudio();

    return () => {
      audioRef.current.pause();
      clearInterval(intervalRef.current);
    };
  }, [item.answer_id, token]);

  useEffect(() => {
    fetchUpvoteStatus();
  }, [item.answer_id, token]);

  const fetchUpvoteStatus = async () => {
    try {
      const response = await axios.post(`${apiendpoint.path}/v1/users/upvotestatus`, 
        { answer_id: item.answer_id },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setHasUpvoted(response.data.has_upvoted);
      setTotalUpvotes(response.data.total_upvotes);
    } catch (error) {
      console.error('Error fetching upvote status:', error);
    }
  };

  const handleUpvote = async () => {
    if (loadingUpvote || hasUpvoted) return;

    setLoadingUpvote(true);
    try {
      const response = await axios.post(`${apiendpoint.path}/v1/users/upvote`,
        { answer_id: item.answer_id },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      if (response.data.message === 'Answer upvoted successfully') {
        setHasUpvoted(true);
        setTotalUpvotes(prev => prev + 1);
      }
    } catch (error) {
      console.error('Error upvoting:', error);
    } finally {
      setLoadingUpvote(false);
    }
  };


  return (
    <li className="question-item">
      <h2 className="question-title">{item.question}</h2>
      <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between",width:100+"%",alignItems:"center"}}>
      <p className="question-author">
        {item.anonymous ? 'Anonymous' : item.question_posted_by} posted this query.
      </p>
      <p className="question-views">
        <span className="view-icon">👁️</span> {item.views}
      </p>
      <p className="question-date">
        {new Date(item.posted_at).toLocaleString()}
      </p>
      </div>
      
      {item.answered && (
        <div className="answer-section">
              <div style={{display:"flex",justifyContent:"space-between",alignItems:"center",flexDirection:"row",width:100+"%"}}>
          <div className="answerer-info">
           <img src={item.answer_posted_pic} alt={item.answer_posted_by} className="avatar" />
            <span className="answerer-name">{item.answer_posted_by} answered your query</span>
            </div>
            <img src={item.answer_posted_brand} alt={item.answer_posted_by} style={{objectFit:"contain",width:100,height:80}}  className="avatar" />
          </div>
          <div className="audio-player">
            <button onClick={togglePlayPause} className="play-pause-button">
              {playing ? '⏸️' : '▶️'}
            </button>
            <input
              type="range"
              value={currentTime}
              max={duration}
              onChange={handleSeek}
              className="audio-slider"
            />
            <span className="audio-time">
              {formatTime(currentTime)} / {formatTime(duration)}
            </span>
          </div>
          <button
            className={`upvote-button ${hasUpvoted ? 'upvoted' : ''}`}
            onClick={handleUpvote}
            disabled={loadingUpvote || hasUpvoted}
          >
            👍 Upvote ({totalUpvotes})
          </button>
        </div>
      )}
    </li>
  );
};

const formatTime = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = Math.floor(seconds % 60);
  return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
};

const mapStateToProps = (state) => ({
  isLoggedIn: state.auth.isLoggedIn,
  user: state.auth.user,
  token: state.auth.token
});

export default connect(mapStateToProps)(QuoraActivity);