import React, { useEffect, useState, useContext } from 'react';
import { Card, Button, Image, Alert } from 'react-bootstrap';
import Lottie from 'react-lottie';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { AppContext } from '../App';
import diamondAnimation from '../images/diamond.json';
import bellAnimation from '../images/bell.json';
import crackeranimation from '../images/crackers.json';
import perfAnimation from '../images/perf.json';
import durAnimation from '../images/dur.json';
import rankAnimation from '../images/rank.json';
import beginnerImg from '../images/beginner.png';
import intermediateImg from '../images/intermediate.png';
import masterImg from '../images/master_new.png';
import championImg from '../images/champion.png';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { apiendpoint } from '../endpoint';
import { Snackbar } from '@mui/material';
import { fontWeight } from '@mui/system';
import Confetti from 'react-confetti'
import ConfettiExplosion from 'react-confetti-explosion';
import styled, { keyframes } from 'styled-components';
import { useQuizContext } from '../quizContext';
import { logout } from '../authActions';

const gradientAnimation = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;

const AnimatedButton = styled.button`
  background: linear-gradient(135deg, #ff9d57, #ff56bb, #935dff,#0060ff);
  background-size: 200% 200%;
  animation: ${gradientAnimation} 4s ease infinite;
  color: white;
  width:250px;
  border: 1px solid #ffffff;
  border-radius: 5px;
  padding: 7px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: transform 0.2s;

  &:hover {
    transform: scale(1.05);
  }

  &:active {
    transform: scale(0.98);
  }
`;

const MyCard = ({ data,token }) => {
  // const { setIsLoading } = useQuizContext(AppContext);
//   const authState = useSelector(state => state.auth);

const navigation = useHistory();
const [loading,setIsLoading] = useState(false)
  const [bookmarkLoading, setBookmarkLoading] = useState(true);
  const [bookMarked, setBookmarked] = useState(false);
  const [showBell, setShowBell] = useState(true);
  const [showDiamond, setShowDiamond] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '' });

  const fetchDiamondStatus = async (solverId) => {
    try {
      const response = await axios.post(apiendpoint.path + '/v1/users/diamond', { solverId }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
      if (response.status === 200 && response.data.hasDiamond) {
        setShowDiamond(true);
      }
    } catch (error) {
      console.error('Error fetching diamond status:', error);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const getBookmarkStatus = async (post_id) => {
    try {
      setBookmarkLoading(true);
      const response = await axios.post(apiendpoint.path + '/v1/users/bookmarkstatus', { post_id }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
      setBookmarkLoading(false);
      if (response.status === 200) {
        setBookmarked(response.data.hasBookmarked);
      }
    } catch (error) {
      setBookmarkLoading(false);
      console.error('Error fetching bookmark status:', error);
    }
  };

  useEffect(() => {
    if (data) {
      setBookmarked(data.bookmarked);
      getBookmarkStatus(data.id);
      fetchDiamondStatus(data.user_id);
    }
  }, [data]);

  const bookmarkPost = async (post_id) => {
    try {
      setIsLoading(true);
      const response = await axios.post(apiendpoint.path + '/v1/users/bookmark', { post_id }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
      if (response.status === 201) {
        setBookmarked(true);
        setIsLoading(false);
         setSnackbar({ open: true, message: "Bookmarked successfully" });
      }
    } catch (error) {
      setIsLoading(false);
      console.error('Error bookmarking post:', error);
    }
  };

  const deleteBookmark = async (post_id) => {
    try {
      setIsLoading(true);
      const response = await axios.post(apiendpoint.path + '/v1/users/delbookmark', { post_id }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
      if (response.status === 200) {
        setBookmarked(false);
        setIsLoading(false);
       setSnackbar({ open: true, message: "Bookmark deleted successfully" });
      }
    } catch (error) {
      setIsLoading(false);
      console.error('Error deleting bookmark:', error);
    }
  };

  const followUser = async (userid) => {
    setShowBell(false);
    setIsLoading(true);
    try {
      const response = await axios.post(apiendpoint.path + '/v1/users/follow', { follower_id: userid }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
      if (response.status === 200) {
        setIsLoading(false);
        setSnackbar({ open: true, message: "We will notify posts of " + data.display_name });
      }
    } catch (error) {
      setIsLoading(false);
      console.error('Error following user:', error);
    }
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
  };

  const timeAgo = (created_at) => {
    const now = new Date();
    const createdAtDate = new Date(created_at);
    const diff = now - createdAtDate;
    const seconds = Math.floor(diff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days > 0) return `${days} day${days > 1 ? 's' : ''} ago`;
    if (hours > 0) return `${hours} hour${hours > 1 ? 's' : ''} ago`;
    if (minutes > 0) return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
    return `${seconds} second${seconds > 1 ? 's' : ''} ago`;
  };

  const getResponses = async (rcid, userid) => {
    setIsLoading(true);
    try {
      const response = await axios.post(apiendpoint.path + '/v1/users/rcresponses', { rc_id: rcid, user_id: userid }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
      if (response.status === 200) {
        setIsLoading(false);
        // navigation.navigate("RCsolution", { responseData: JSON.stringify(response.data) });
      }
    } catch (error) {
      setIsLoading(false);
      console.error('Error fetching responses:', error);
    }
  };

  const getResponsesCompare = async (rcid, userid) => {
    setIsLoading(true);
    try {
      const response = await axios.post(apiendpoint.path + '/v1/users/rccompare', { rc_id: rcid, user_id: userid }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
      if (response.status === 200) {
        setIsLoading(false);
        // navigation.navigate("RCsolutionCompare", { responseData: JSON.stringify(response.data) });
      }
    } catch (error) {
      setIsLoading(false);
      console.error('Error fetching responses:', error);
    }
  };

  const fetchTest = async (selectedRcId) => {
    try {
        setIsLoading(true)
        const headers = {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
        };

        const postData = selectedRcId ? { rcId: selectedRcId } : {};

        const response = await axios.post(apiendpoint.path + "/v1/users/getfreshrcweb", postData, { headers });
      
        // if(response.data.weakAreamain==null||response.data.weakCategorymain==null){
        //     navigation.push('/testing',{data:response.data.rcDetails})
        // }
        // else{
        //     setWeakArea(response.data.weakAreamain)
        //     setweakCategory(response.data.weakCategorymain)
        //     setModal(true)
        // }
        navigation.push('/testing',{data:response.data.rcDetails})
        // settestloading(false);
    } catch (error) {
        // dispatc(logout(
        // )); 
        // navigation.replace('/')
        // console.error('Error fetching posts:', error);
        // settestloading(false);
    }
};


  const getQuestions = async (rcId) => {
    setIsLoading(true);
    try {
      const response = await axios.post(apiendpoint.path + '/v1/users/getfreshrc', { rcId }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
      if (response.status === 200) {
        setIsLoading(false);
        // navigation.replace("RCtest", { responseData: JSON.stringify(response.data) });
      }
    } catch (error) {
      setIsLoading(false);
      console.error('Error fetching questions:', error);
    }
  };

  const handleCardClick = () => {
    if (data && data.attempted) {
      setSnackbar({ open: true, message: "Comparisons are available on phone app only for now!" });
    } else {
      fetchTest(data.rc_id);
    }
  };

  const handleBookmarkClick = (event) => {
    event.stopPropagation();
    if (bookMarked) {
      deleteBookmark(data.id);
    } else {
      bookmarkPost(data.id);
    }
  };

  const handleLearnFromMistakesClick = (event) => {
    event.stopPropagation();
    setSnackbar({ open: true, message: "This feature is launching to public in the next release!" });
  };

  return (
    <Card  elevation={20} style={styles.card}>
      <Card.Body >
        <Card.Title style={{textAlign:"left",fontSize:18}} 
        
        >
          { data.title}
        </Card.Title>
        <div  style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginTop: -5 }}>
            <p style={styles.time}>{"Solved " + timeAgo(data.created_at) + " by " + data.display_name}</p>
            {showDiamond && <Lottie options={{ animationData: diamondAnimation }} height={25} width={25} style={{marginTop:-12}} />}
          </div>
          {!data.followed && showBell &&
            <div onClick={() => {
              followUser(data.user_id);
            setSnackbar({ open: true, message:   data.display_name+ " added to your study buddies!" });
            }}>
              <Lottie options={{ animationData: bellAnimation }} height={30} width={30} />
            </div>
          }
        </div>
        <div onClick={handleCardClick} style={styles.info}>
          <div style={styles.imageContainer}>
            <Image style={styles.image} src={
              data.score < 50 ? beginnerImg :
              data.score >= 50 && data.score < 75 ? intermediateImg :
              data.score >= 75 && data.score < 90 ? masterImg :
              championImg
            } />
          </div>
          <div onClick={handleCardClick} style={styles.details}>
            <div style={styles.score}>
              <p>Score</p>
              <Lottie options={{ animationData: perfAnimation }} height={30} width={30} />
              <p style={{fontWeight:"bold"}}>{parseFloat(data.score).toFixed(2) + "%"}</p>
            </div>
            <div style={styles.duration}>
              <p>Duration</p>
              <Lottie options={{ animationData: durAnimation }} height={30} width={30} />
              <p style={{fontWeight:"bold"}}>{formatTime(data.duration)}</p>
            </div>
            <div style={styles.rank}>
              <p>Rank</p>
              <Lottie options={{ animationData: rankAnimation }} height={30} width={30} />
              <p style={{fontWeight:"bold"}}> {data.rank}</p>
            </div>
          </div>
        </div>
        {data && data.attempted && data.myUid && data.solved_by && data.myUid !== data.solved_by && <p style={{ color: "red", textAlign: "center", fontSize: 15, fontWeight: "bold", margin:0 }}>VS</p>}
        {data && data.attempted && data.myUid && data.solved_by && data.myUid !== data.solved_by && <p style={{ color: "#7f388b", textAlign: "center", fontSize: 10, fontWeight: "bold", marginTop: 15 }}>My performance in this test</p>}
        {data && data.attempted && data.myUid && data.solved_by && data.myUid !== data.solved_by && (
          <div onClick={handleCardClick} style={styles.info}>
            <div style={styles.imageContainer}>
              <Image style={styles.image} src={
                data.myScore < 50 ? beginnerImg :
                data.myScore >= 50 && data.myScore < 75 ? intermediateImg :
                data.myScore > 74 && data.myScore < 90 ? masterImg :
                championImg
              } />
            </div>
            <div style={styles.details}>
              <div style={styles.score}>
                <p>Score</p>
                <Lottie options={{ animationData: perfAnimation }} height={30} width={30} />
                <p style={{fontWeight:"bold"}}>{parseFloat(data.myScore).toFixed(2) + "%"}</p>
              </div>
              <div style={styles.duration}>
                <p>Duration</p>
                <Lottie options={{ animationData: durAnimation }} height={30} width={30} />
                <p style={{fontWeight:"bold"}}>{formatTime(data.myDuration)}</p>
              </div>
              <div style={styles.rank}>
                <p>Rank</p>
                <Lottie options={{ animationData: rankAnimation }} height={30} width={30} />
                <p style={{fontWeight:"bold"}}>{data.myRank}</p>
              </div>
            </div>
          </div>
        )}
        {bookMarked ? (
          <div style={{display:"flex",flexDirection:"row",justifyContent:"space-evenly",alignItems:"center"}}>
             <Button color={"#7f388b"} variant="primary" style={styles.reactButton}  onClick={handleBookmarkClick}>Delete Bookmark</Button>
      
      <AnimatedButton onClick={handleLearnFromMistakesClick}>
        <div style={{fontWeight:"bold",color:'white',fontSize:16}}>
  Learn From Mistakes
        </div>
      </AnimatedButton>  
      </div>
      ) : (
        <div style={{display:"flex",flexDirection:"row",justifyContent:"space-evenly",alignItems:"center"}}>
        <Button color={"#7f388b"} variant="primary" style={styles.reactButton} onClick={() => bookmarkPost(data.id)}>Bookmark</Button>
      
 <AnimatedButton>
   <div style={{fontWeight:"bold",color:'white',fontSize:16}}>
   Learn From Mistakes
   </div>
 </AnimatedButton>  
         </div>  )}
      </Card.Body>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbar.message}
      />
    </Card>
  );
};

const styles = {
  card: {
    margin: '20px',
    padding: '20px',
    borderRadius: '15px',
    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
    width:600,
    cursor:"pointer"
  },
  container: {
    padding: '10px'
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  title: {
    fontSize: '18px',
    fontWeight: 'bold',
  },
  time: {
    fontSize: '14px',
    color: '#555',
  },
  info: {
    display: 'flex',
    marginTop: '10px'
  },
  imageContainer: {
    marginRight: '10px'
  },
  image: {
    width: '100px',
    height: '100px'
  },
  details: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent:"space-evenly",
    width:100+"%"
  },
  score: {
    display: 'flex',
    alignItems: 'center',
    flexDirection:"column",
    justifyContent:"center",
    alignItems:"center",
    borderStyle:"solid",
    paddingVertical:4,
    paddingLeft:16,
    paddingRight:16,
    borderColor:"#eeeeee",
    borderRadius:5,
    height:80
  },
  duration: {
    display: 'flex',
    alignItems: 'center',
    flexDirection:"column",
    justifyContent:"center",
    alignItems:"center",
    borderStyle:"solid",
    paddingVertical:8,
    paddingLeft:16,
    paddingRight:16,
    borderColor:"#eeeeee",
    borderRadius:5,
    height:80
  },
  rank: {
    display: 'flex',
    alignItems: 'center',
    flexDirection:"column",
    justifyContent:"center",
    alignItems:"center",
    borderStyle:"solid",
    paddingVertical:8,
    paddingLeft:16,
    paddingRight:16,
    borderColor:"#eeeeee",
    borderRadius:5,
    height:80
  },
  reactButton: {
    width: '250px',
    backgroundColor:"#7f388b",
    fontWeight:"bold",
    borderRadius:5,
    marginTop:0,
  fontSize:16,
  padding:7,
  },
  lottieContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 1,
    marginLeft:-180,
    marginBottom:-200
  },
};

export default MyCard;