import React, { Component } from 'react';

import VideoPlayer from 'react-video-js-player';


    class Mediaplayer extends Component{

    

            render(){

        return(
        

<div  style={{position:"relative",width:"auto", height:"auto",border:16+"px black solid",margin:"auto",marginTop:10, marginBottom:10, borderTopWidth:60+"px",borderBottomWidth:60+"px",borderRadius:36+"px",backgroundColor:"black"}}>
    
        <VideoPlayer src={"https://storage.googleapis.com/assets_verboace/teaser-3.mov"} width="330" height="540">     

        </VideoPlayer>
        </div>
        )
        }   


    }
export default Mediaplayer;